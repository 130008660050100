import React,{useEffect, useState} from 'react'
import '../CreateDecision/DefineScoreCard/definescorecard.css'  
import cardCreatedIcon from '../../../assests/icons/amlFound.png'
import cardErrorIcon from '../../../assests/icons/amlEmpty.png'
import Spinner from '../../../svg/Spinner/Spinner'
import { useForm } from "react-hook-form";
import { useDispatch,useSelector } from 'react-redux'
import { defineScoreCardName,selectSelfServiceState,editScoreCardDetails } from '../../../redux/Slices/SelfServiceDecision'
import { PostDataStatus } from '../../../library/Variables'
import Utils from '../../../library/Utils'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './SelfScoreCard.css'



function EditScoreCard({scoreCardKey,accessToken,closeModal,name,scoreCardBaseScore,setScoreCardBaseScore,setPreviewCard,previewCard}) {
    const dispatch = useDispatch()
    const { register,getValues,setValue, formState: { errors }, handleSubmit,trigger, reset } = useForm()
    const [cardName,setCardName] = useState(name)
    const [cardBaseScore,setCardBaseScore] = useState(scoreCardBaseScore)
    const [cardState,setCardState] = useState()
    const [scoreCardDisplay,setScoreCardDisplay] = useState(<></>)
    let cardObj
    const scorecardForm = <div>
                            <section style={{rowGap:"20px",padding:"20px",boxSizing:"border-box",display:'flex',flexDirection:"column",justifyContent:"space-around",borderRadius:"5px",backgroundColor:"white",marginTop:"20px"}}>
                                <div style={{display:'flex',flexDirection:'column'}}> 
                                    <label style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Name</label>
                                    <input  style={{border:'2px solid rgba(223, 232, 255, 1)',outline:"none",height:"45px",paddingLeft:"30px"}} 
                                            placeholder='input name' 
                                            autocomplete="off" 
                                            type='text'
                                            value={cardName}
                                            onChange={(e)=>setCardName(e.target.value)}
                                    />
                                            
                                </div>

                                <div style={{display:'flex',flexDirection:'row',columnGap:'25px'}}> 
                                    <section>
                                        <p style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Base Score</p>
                                            <div style={{display:'flex',justifyContent:"left",columnGap:"15px"}}>
                                                <section style={{display:'flex',alignItems:"center",justifyContent:"center",columnGap:'10px'}}>
                                                    <input style={{border:'2px solid rgba(223, 232, 255, 1)',
                                                                    outline:"none",height:"55px",width:"120px"
                                                                    ,paddingLeft:'5px'}} placeholder='0' 
                                                                    value={cardBaseScore}
                                                                    onChange={(e)=>setCardBaseScore(e.target.value)}
                                                                    autocomplete="off" type='NUMBER'  />
                                                </section>
                                            </div>
                                    </section>
                                
                                    
                                </div>
                                <div style={{display:"flex",justifyContent:"flex-end",columnGap:"15px"}}>
                                    <section style={{display:'flex',alignItems:'center',justifyContent:'end'}}>
                                        <div className='cancelEditCard' onClick={()=>closeModal()}>Cancel</div>
                                    </section>

                                    <section style={{display:'flex',alignItems:'center',justifyContent:'end'}}>
                                        <div className='saveEditCard' onClick={()=>saveScoreCard()}>Save</div>
                                    </section>
                                </div>
                            </section>
                                
                        </div>


    const saveScoreCard = ()=>{
        const allFields = getValues()
        cardObj = { key:scoreCardKey,
                    name:cardName,
                    baseScore:parseInt(cardBaseScore)
                    }
        //setCardState(cardObj)

        if(!Utils.isFieldEmpty(cardObj?.name) && !Utils.isFieldEmpty(cardObj?.baseScore) 
           ){
                dispatch(editScoreCardDetails({data:cardObj,accessToken}))
        }
        else{
            toast.info('Fill all neccessary Fields.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
        }
        
    }
    const selfScoreDef = useSelector(selectSelfServiceState)

    const scoreDefSuccess = <section className='successHoldBody'>
                                <span className='imgFlex'>
                                    <img src={cardCreatedIcon}/>
                                </span>
                                <span className='cardInfo'>Your Card was edited successfully.</span>
                                <span className='cardDirection'>Click done to see</span>

                                <section style={{display:"flex",
                                                 alignItems:"center",
                                                 justifyContent:"center",
                                                 width:"100%"}}>
                                    <span onClick={()=>closeModal()} className='button'>
                                        Done
                                    </span>
                                </section>
                                    
                            </section>

    const scoreDefFailure = <section className='successHoldBody'>
                                <span className='imgFlex'>
                                    <img src={cardErrorIcon}/>
                                </span>
                                <span className='cardInfo'>Card edit failed</span>
                                <span className='cardFailed'>Something went wrong</span>

                                <section style={{display:"flex",
                                                alignItems:"center",
                                                justifyContent:"center",
                                                width:"100%"}}>
                                    <span onClick={()=>closeModal()} className='button'>
                                        Close
                                    </span>
                                </section>
                                    
                            </section>
    
 
    useEffect(()=>{
        if(selfScoreDef.selfServiceEditScoreCardStatus === PostDataStatus.INITIAL){
            setScoreCardDisplay(scorecardForm)
        }

        else if(selfScoreDef.selfServiceEditScoreCardStatus === PostDataStatus.FETCHING){
            setScoreCardDisplay(<Spinner/>)
        }

        else if(selfScoreDef.selfServiceEditScoreCardStatus === PostDataStatus.SUCCESS){
            
            setPreviewCard(()=>({...previewCard,name:cardName})) 
            setScoreCardBaseScore(cardBaseScore)
            setScoreCardDisplay(scoreDefSuccess)
        }

        else if(selfScoreDef.selfServiceEditScoreCardStatus === PostDataStatus.FAILURE){
            setScoreCardDisplay(scoreDefFailure)
        }
    },[selfScoreDef.selfServiceEditScoreCardStatus,cardName,cardBaseScore])

    



  return (
    <div style={{height:'350px',paddingTop:"20px"}}>
        <section style={{display:'flex',alignItems:'center',justifyContent:"space-between"}}>
            <div style={{display:'flex',flexDirection:'column',rowGap:"10px"}}>
                <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"24px",fontWeight:'700'}}>
                    Edit Score card
                </span>
            </div>
          
        </section>

        <section style={{margin:'10px 0px',padding:'0px 0px'}}>
            {scoreCardDisplay}
        </section>
        
    </div>
  )
}

export default EditScoreCard


/**
 *  <section style={{display:'flex',alignItems:'center',justifyContent:"space-between"}}>

            {selfServiceState.parameterOptionsStatus === PostDataStatus.SUCCESS && <div onClick={()=>{dispatch(resetRules()); 
                            navigate('/DecisionEngineSelfService/RulesCreation')}} 
                            className='createEngineBut'>
                    Create Rules
            </div>}
        
        </section>
 */