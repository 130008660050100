import React, { useEffect, useMemo, useState } from "react";
import {
  useNavigate
} from "react-router-dom";
import { 
  useSelector,
  useDispatch
} from "react-redux";
import { 
  selectBureauState,
  getAllBureauReports,
  addNewBureauReportAsync,
  getAllCrbReports,
  resetAll
} from "../../redux/Slices/Bureau";
import { CrbHistoryMainSerializer } from "../Statement/Bureau/CrbSerializer/CrbHistorySerializer";
import { CrbHistoryKenyaSerializer } from "../Statement/Bureau/Serializers/KenyaCrbSerializer/KenyaCrbHistorySerializer";
import Utils from "../../library/Utils";
import Spinner from "../../svg/Spinner/Spinner";
import "./BureauStandalone.css";
import BureauConsumerBasic from "../Statement/Bureau/BureauCrbReport/BureauConsumerBasic/BureauConsumerBasic";
import BureauReportCentralPremium from "../Statement/Bureau/Report/BureauReportCentralPremium";
import BureauForm from "../Statement/Bureau/Form/BureauForm";
import BureauReport from "../Statement/Bureau/Report/BureauReport";
import { CountryCodes } from "../../library/CountryCodes";
import Modal from "react-modal";
import GhanaCrbModal from "../../components/Modals/CrbModals/CrbModal";
import GhanaCrbFetchModal from "../../components/Modals/CrbModals/CrbGetModal";
import { BureauGenerateViewType, 
         CreditCheckCardFields, 
         FetchDataStatus, 
         PostDataStatus,
         CrbGhanaCardFields,
         CRBKenyaCheckTypes,
         CrbKenyaCardFields } from "../../library/Variables";

import BureauCreditCheck from "../../components/Modals/BureauCreditCheck";
import { Table,SelectColumnFilter, DateRangeColumnFilter } from "../../components/Table/Table";
import CreditCheckCards from "../Statement/Bureau/Sections/CreditCheckCards";
import BureauReportBusiness from "../Statement/Bureau/Report/BureauReportBusiness";
import BureauReportIndividual from "../Statement/Bureau/Report/BureauReportIndividual";
import { CamelDataSerializer } from "../Statement/Bureau/CrbSerializer/CrbCamelCaseSerializer";
import { CRBGhanaCheckTypes } from "../../library/Variables";
import { resetConsumerCreditPost,
         resetCommercialCreditGet, 
         resetCommercialCreditPost,
         resetConsumerCreditGet,
         getConsumerCredit,
         getCommercialCredit,
         selectCrbGhanaData } from "../../redux/Slices/CRB/Ghana/CrbGhana";
import { resetCrbKenyaBasic,resetCrbKenyaAdvanced,resetCrbKenyaBusiness } from "../../redux/Slices/CRB/Kenya/CrbKenya";
import PostKenyaCrbModal from "../../components/Modals/CrbModals/KenyaCrb/PostKenyaCrbModal";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import crbLogoHead from '../../assests/svg-icons/crbLogoHead.svg'
import BureauDataKenBasic from "./BureauDataByCountry/Kenya/BureauDataKenBasic";


function CreditBureauStandAlone(props) {
  let content = <Spinner />;
  let subheader = null;

  const accessToken = props.accessToken;
  const keyFromRoute = parseInt(0);
  const configuration = props.configuration;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bureau = useSelector(selectBureauState);
  const camelState = useSelector(selectCrbGhanaData)
  const [view, setView] = useState(BureauGenerateViewType.INITIAL);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [crbModalIsOpen, setCrbModalOpen] = useState(false);
  const [crbGetModalIsOpen, setCrbGetModalOpen] = useState(false);
  const [crbPostKenyaModalIsOpen, setCrbPostKenyaModalOpen] = useState(false);
  const [fieldType, setFieldType] = useState(null);
  const [fetchFields,setFetchFields] = useState(null)
  const [report, setReport] = useState(null);
  const [reportFormat,setReportFormat] = useState(null)
  const [viewReport,setViewReport] = useState(false)
  const [crbHistoryState,setCrbHistoryState] = useState('')
  const [crbHistoryStateKenya,setCrbHistoryStateKenya] = useState('')
  const [kenyaCrbDataIndex,setKenyaCrbDataIndex] = useState()
  const [premblyUi,setPremblyUi] = useState()

  const consumerFunctions = (data,status)=>{
  
    dispatch(getConsumerCredit({ref:data.row.original.reference,accessToken}));
    setFetchFields('CONSUMER');
    setReportFormat(data.row.original.reportType)
    openCrbGetModal()

    /*
    if(status === 'pending'){
          toast.error("Your CRB request is being processed.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
        else{
          dispatch(getConsumerCredit({ref:data.row.original.reference,accessToken}));
          setFetchFields('CONSUMER');
          setReportFormat(data.row.original.reportType)
          openCrbGetModal()
        }
     
        */
  
  }

  const commercialFunctions = (data)=>{
    //dispatch(getCommercialCredit({ref:data.row.original.reference,accessToken}));
    //setFetchFields('COMMERCIAL');
    //setReportFormat(data.row.original.reportType)
    //openCrbGetModal()
  }

  useEffect(()=>{
    if(bureau.CrbHistoricalDataStatus === FetchDataStatus.SUCCESS){
      if(configuration.countryCode === CountryCodes.GHANA){
        setCrbHistoryState(CrbHistoryMainSerializer(bureau.CrbHistoricalData))
      }

      else if(configuration.countryCode === CountryCodes.KENYA){
          setCrbHistoryStateKenya(CrbHistoryKenyaSerializer(bureau.CrbHistoricalData))
      }
      
    }
  },[bureau.CrbHistoricalDataStatus])


  const KenyanView = (data,index)=>{
        setKenyaCrbDataIndex(index)
        setPremblyUi(false)
        setViewReport(true)
  }

  const KenyanFalse = (index)=>{
        setKenyaCrbDataIndex(index)
        setPremblyUi(true)
        setViewReport(true)

  }

  const emptyCallData = ()=>{



}
  
  const theaders = useMemo(() => [
    {
      Header: "Status",
      accessor: "status",
      disableSortBy: true,
      Cell: data => {
        let status = !Utils.isFieldEmpty(data.row.original.status) ? data.row.original.status : "Found";

        return (
          <div className="table-row-status" style={{
            color: Utils.formatTextColor(status, "#808080", "#c1121f", "#00770C", "#FF725E")
          }}>{status.toUpperCase()}</div>
        )
      }
    },
    { 
      Header: "Created Date",
      accessor: "createdDate",
      disableSortBy: true,
      Cell: data => {
        return Utils.formatLongDateAndTime(data.row.original.createdDate)
      }
    },
    {
      Header: "Full Name",
      id: "fullName",
      disableSortBy: true,
      Cell: data => {
        let fullName = !Utils.isFieldEmpty(data.row.original.fullName) 
          ? data.row.original.fullName 
          : data.row.original.firstName + " " + data.row.original.lastName;

        return fullName;
      }
    },
    { 
      Header: "BVN/RC Number",
      id: "bvn",
      accessor: "bvn",
      Cell: data => {
        return data.row.original.bvn || data.row.original.businessRegisterationNumber
      },
      disableSortBy: true
    },
    {
      Header: "Tag",
      Cell: data => {
        return "CREDITBUREAU"
      },
      disableSortBy: true
    },
    {
      id: "button-column",
      disableSortBy: true,
      Cell: data => {
        let status = !Utils.isFieldEmpty(data.row.original.status) ? data.row.original.status : "Found";
        let isDisabled = status.toUpperCase() === "FOUND" ? false : true;

        return (
          <button className="button-square button-solid" onClick={() => {
            setView(BureauGenerateViewType[(data.row.original.providerSource).toUpperCase()]);
            setReport(data.row.original);
            setViewReport(true)
          }} disabled={isDisabled}>View</button>
        );
      }
    }]
  )



  const theadersCrb = useMemo(() => [
   
    { 
      Header: "Created Date",
      accessor: "createdDate",
      disableSortBy: false,
      Filter: DateRangeColumnFilter,
      filter: 'dateRange',
      Cell: data => {
        return Utils.formatLongDateAndTime(data.row.original.createdDate)
      }
    },
    {
      Header: "Credit Type",
      accessor: "creditType",
      id: "creditType",
      disableSortBy: false,
      Cell: data => {
        return data.row.original.creditType;
      },
      Filter: SelectColumnFilter,
      filter: 'includes',
    },
    {
      Header: "Report Type",
      accessor: "reportType",
      id: "reportType",
      disableSortBy: false,
      Cell: data => {
        return data.row.original.reportType;
      },
      Filter: SelectColumnFilter,
      filter: 'includes',
    },
    { 
      Header: "ID Number",
      accessor: "IDNUMBER",
      id: "idnumber",
      Cell: data => {
        return data.row.original.IDNUMBER 
      },
      disableSortBy: false,
    },
    { 
      Header: "Status",
      accessor: "status",
      id: "status",
      Cell: data => {
        return <span style={{color:data.row.original.status==='pending'?'black':'green'}}>
                  {data.row.original.status}
                </span>
      },
       Filter: SelectColumnFilter,
       filter: 'includes',
      disableSortBy: false,
    },
    {
      id: "button-column",
      disableSortBy: true,
      Cell: data => {
        let status = data.row.original.status;
        let isDisabled = status.toUpperCase() === "FOUND" ? false : true;

        return (
          <button className={(data.row.original.creditType==='CONSUMER CREDIT' && data.row.original.status==='found')?
                            "button-square button-solid":"disableCrbButton"} onClick={() => {
            data.row.original.creditType==='CONSUMER CREDIT'?
            consumerFunctions(data,status):
            commercialFunctions(data)
          }} >View</button>
        );
      }
    }]
  )



  


  

  const theadersCrbKenya = useMemo(() => [
   
    { 
      Header: "Status",
      accessor: "status",
      id: "status",
      Cell: data => {
        return <span style={{color:'green'}}>
                  {data.row.original.status}
                </span>
      },
       Filter: SelectColumnFilter,
       filter: 'includes',
      disableSortBy: false,
    },


    { 
      Header: "Created Date",
      accessor: "createdDate",
      disableSortBy: false,
      Filter: DateRangeColumnFilter,
      filter: 'dateRange',
      Cell: data => {
        return Utils.formatLongDateAndTime(data.row.original.createdDate)
      }
    },
    {
      Header: "Report",
      accessor: "report",
      id: "reportType",
      disableSortBy: false,
      Cell: data => {
        return data.row.original.report;
      },
      Filter: SelectColumnFilter,
      filter: 'includes',
    },
    {
      Header: "Full Name",
      accessor: "fullName",
      id: "fullName",
      disableSortBy: false,
      Cell: data => {
        return data.row.original.fullName;
      },
      Filter: SelectColumnFilter,
      filter: 'includes',
    },
    {
      Header: "Id Number",
      accessor: "idNumber",
      id: "idNumber",
      disableSortBy: false,
      Cell: data => {
        return data.row.original.idNumber;
      },
      Filter: SelectColumnFilter,
      filter: 'includes',
    },
    { 
      Header: "Date of Birth",
      accessor: "dateOfBirth",
      id: "dateOfBirth",
      Cell: data => {
        return data.row.original.dateOfBirth 
      },
      disableSortBy: false,
    },
    { 
      Header: "Tag",
      accessor: "tag",
      id: "tag",
      Cell: data => {
        return <span>
                  {data.row.original.tag === 'BASIC'?'INDIVIDUAL BASIC':data.row.original.tag === 'ADVANCED'?'INDIVIDUAL ADVANCED':'BUSINESS'}
                </span>
      },
       Filter: SelectColumnFilter,
       filter: 'includes',
      disableSortBy: false,
    },
    {
      id: "button-column",
      disableSortBy: true,
      Cell: data => {
        //let status = data.row.original.status;
        //let isDisabled = status.toUpperCase() === "FOUND" ? false : true;
        return (
          <button 
          onClick={()=>{data.row.original.tag !== 'BASIC'? emptyCallData()
                        :new Date(data.row.original.createdDate) < new Date(2024, 7, 18) ? 
                        KenyanView(data,data.row.original.index) : 
                        KenyanFalse(data.row.original.index)
            }}

            className="button-square button-solid"
          >View</button>
        );
      }
    }]
  )




  const params = {
    accessToken: accessToken,
    key: keyFromRoute,
  }
  
  const customStyles = {
    content: {
      transition: "0.125s ease-in-out",
      transform: "scale(1)",
      height: "33%",
      width: "50%",
      top: "50%",
      left: "33%",
      padding: "0 0 0 0",
      fontFamily: configuration.fontFamily,
      overflowY: "scroll"
    },
    overlay: {
      zIndex: 1000,
      position: "absolute"
    }
  };

  const openModal = () => {
    setIsOpen(true);
  }

  const afterOpenModal = () => {}

  const closeModal = () => {
    setIsOpen(false);
  }


  const openCrbModal = () => {
    setCrbModalOpen(true);
  }

  const afterOpenCrbModal = () => {}

  const closeCrbModal = () => {
    setCrbModalOpen(false);
    dispatch(resetCommercialCreditPost())
    dispatch(resetConsumerCreditPost())
  }





  const afterOpenCrbKenyaPostModal = () => {}


  const openCrbKenyaPostModal = () => {
    setCrbPostKenyaModalOpen(true);
  }
  const closeCrbKenyaPostModal = () => {
    setCrbPostKenyaModalOpen(false);
    dispatch(resetCrbKenyaBusiness())
    dispatch(resetCrbKenyaAdvanced())
    dispatch(resetCrbKenyaBasic())
  }





  const closeCrbGetModal = () => {
    setCrbGetModalOpen(false);
  }


  const openCrbGetModal = () => {
    setCrbGetModalOpen(true);
  }

  


  const handleCrbSelection = (index) => {
    setFieldType(CrbGhanaCardFields[index])
    openCrbModal();
  }

  const handleCrbKenyaSelection = (index) => {
    setFieldType(CrbKenyaCardFields[index])
    openCrbKenyaPostModal();
  }


  const handleCardSelected = (index) => {
    setFieldType(CreditCheckCardFields[index]);
    openModal();
  }

  const submitCallbackFunction = (formParams) => {
    setView(BureauGenerateViewType.INITIAL);
    dispatch(addNewBureauReportAsync(formParams));
  }


  //to trigger fetch of historical CRB records
  useEffect(() => {
    if(bureau.CrbHistoricalDataStatus === FetchDataStatus.INITIAL) {
      dispatch(getAllCrbReports(params));
    }

    if(bureau.CrbHistoricalDataStatus === FetchDataStatus.FAILURE) {
      toast.error(bureau.CrbHistoricalDataErrorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      //navigate("/error");
    }
  }, [bureau.CrbHistoricalDataStatus, dispatch])


  useEffect(() => {
    if(bureau.addNewReportStatus === PostDataStatus.SUCCESS || bureau.addNewReportStatus === PostDataStatus.FAILURE) {
      dispatch(resetAll());
    }
  }, [bureau.addNewReportStatus, dispatch])

  if(configuration.featureFlags.isBlacklistEnabled === true || configuration.featureFlags.isCreditchekEnabled === true) {
    subheader = <p className="text-medium-dark">Tap any of the cards below to proceed with check</p>
  }

  if(view === BureauGenerateViewType.INITIAL) {
    content = <div>
      <div className="bureau-check-header-container">
        
        {(configuration.featureFlags.isCustomBureauDataEnabled === true && configuration.countryCode === CountryCodes.NIGERIA) &&
          <button className="button-square button-solid" onClick={() => setView(BureauGenerateViewType.FORM)}>Add Custom Data</button>
        }
      </div>
      <div className="bureau-creditcheck-type-container">
      {
        configuration.countryCode === "NGA" ?
        <>
            {configuration.featureFlags.isBlacklistEnabled &&
              <CreditCheckCards parentCallback={() => handleCardSelected(0)} 
                field={{ title: "CRC Nano Data", subtitle: "This check requires BVN" }} />
            }
            {configuration.featureFlags.isCreditchekEnabled &&
              <>
                <CreditCheckCards parentCallback={() => handleCardSelected(1)} 
                  field={{ title: "Bureau Data for Individual", subtitle: "This check requires BVN of the individual" }} />
                <CreditCheckCards parentCallback={() => handleCardSelected(2)}
                  field={{ title: "Bureau Data for Business", subtitle: "This check requires BVN of the business" }} />
              </>
            }
        </>
        :
        configuration.countryCode === CountryCodes.GHANA ?
        <>
        <CreditCheckCards parentCallback={() => handleCrbSelection(1)} 
            field={{ title: "Bureau Data for Individual", 
                     subtitle: "This check requires consumer name and date of birth.",
                     type:CRBGhanaCheckTypes.CONSUMER }} 
                  
        />

        <CreditCheckCards parentCallback={() => handleCrbSelection(0)} 
            field={{ title: "Bureau Data for Business", 
                     subtitle: "This check requires enterprise name.",
                     type:CRBGhanaCheckTypes.COMMERCIAL}} 

        />

        </>:
        configuration.countryCode === CountryCodes.KENYA?
        <>

          <CreditCheckCards parentCallback={() => handleCrbKenyaSelection(1)} 
              field={{ title: "Bureau Data for Individual", 
                      subtitle: "This check requires Bureau data of the individual.",
                      type:CRBKenyaCheckTypes.INDIVIDUAL }} />

          <CreditCheckCards parentCallback={() => handleCrbKenyaSelection(0)} 
              field={{ title: "Bureau Data for Business", 
                      subtitle: "This check requires RC Number of the Business.",
                      type:CRBKenyaCheckTypes.BUSINESS}} />

          
        </>:
        <div style={{minHeight:"150px",
                                display:'flex',
                                flexDirection:'column',
                                alignItems:'center',
                                justifyContent:"space-around",
                                backgroundColor:'white',
                                width:'100%',
                                borderRadius:'10px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                margin:'30px auto'}}>
                        <p style={{fontWeight:'700',
                                   fontStyle:'italic',
                                   fontSize:'22px'}}>
                                Anticipate Bureau Data!
                        </p>

                        <p style={{textAlign:'center',
                                    fontSize:'15px',
                                    marginTop:'10px'}}>
                                    Coming Soon
                        </p>
                    </div>
      }
        

        
      </div>
      {bureau.CrbHistoricalDataStatus === FetchDataStatus.FETCHING
        ? <Spinner />
        : !Utils.isFieldEmpty(bureau.CrbHistoricalData) 
          && <>
              <hr />
              <div className="bureau-all-reports-table-container">
                <h3 className="detail-header">Check History</h3>
                {
                  configuration.countryCode === CountryCodes.NIGERIA?
                  <Table data={bureau.CrbHistoricalData} 
                         columns={theaders} 
                         tableClass="bureau-all-reports-table"
                         pagination={true} pageSize={[10]}
                         tableFilter={true} 
                         rowClass={"table-row-two-colored"} />
                         :
                  <>
                    {!viewReport?<>
                    
                            <div className='top-section'>
                              <span className={viewReport?'span-view-report':'span-view-history'}>History</span>
                              <span>/</span>
                              <span className={viewReport?'span-view-history':'span-view-report'} >View report</span>
                            </div>

                            {
                              (Utils.isFieldEmpty(crbHistoryState) && Utils.isFieldEmpty(crbHistoryStateKenya))?
                              
                              <div style={{width:'100%',textAlign:'center',marginTop:'30px'}}>
                                    You currently have no historical Data on CRB.
                              </div>
                              :
                              <>
                              {configuration.countryCode=== CountryCodes.GHANA? 
                              <Table data={crbHistoryState}
                                columns={theadersCrb} 
                                tableClass="bureau-all-reports-table" 
                                pagination={true} pageSize={[10]}
                                tableFilter={true} 
                                rowClass={"table-row-two-colored"}
                                

                              />
                              :configuration.countryCode=== CountryCodes.KENYA? 
                              <Table data={crbHistoryStateKenya}
                                columns={theadersCrbKenya} 
                                tableClass="bureau-all-reports-table" 
                                pagination={true} pageSize={[10]}
                                tableFilter={true} 
                                rowClass={"table-row-two-colored"}
                                

                              />
                              :''
                              }
                              </>
                            }
                            

                    </>
                    :
                    <>
                      {configuration.countryCode=== CountryCodes.GHANA?
                       <BureauConsumerBasic setViewReport={setViewReport} 
                                           reportFormat={reportFormat}
                                            viewReport={viewReport}
                                           />:
                      configuration.countryCode===CountryCodes.KENYA?
                        <BureauDataKenBasic setViewReport={setViewReport} 
                                            reportFormat={'Individual'}
                                            viewReport={viewReport}
                                            index={kenyaCrbDataIndex}
                                            premblyUi={premblyUi}
                                            configuration={configuration}
                        />: ''
                      }
                    </>
                    }
                
                  </>
                }
                
              </div>
            </>
      }
    </div>
  } else if(view === BureauGenerateViewType.FORM) {
    content = <BureauForm callbackFunction={() => setView(BureauGenerateViewType.INITIAL)} keyFromRoute={0}
      submitCallbackFunction={(formParams) => submitCallbackFunction(formParams)} accessToken={props.accessToken} />
  } else if(view === BureauGenerateViewType.CUSTOM || view === BureauGenerateViewType.BLACKLIST) {
    content = <BureauReport report={report} configuration={configuration} callbackFunction={() => setView(BureauGenerateViewType.INITIAL)} />
  } else if(view === BureauGenerateViewType.CREDITCHEK) {

    
    if(!Utils.isFieldEmpty(report.businessRegisterationNumber && report.data[0]?.sourceType!=='FIRST_CENTRAL_PREMIUM')) {
      content = <BureauReportBusiness report={report} configuration={configuration} callbackFunction={() => setView(BureauGenerateViewType.INITIAL)} />
    } else if(Utils.isFieldEmpty(report.businessRegisterationNumber && report.data[0]?.sourceType!=='FIRST_CENTRAL_PREMIUM')){
      content = <BureauReportIndividual report={report} configuration={configuration} callbackFunction={() => setView(BureauGenerateViewType.INITIAL)} />
    }

    if(report.data[0]?.sourceType==='FIRST_CENTRAL_PREMIUM'){
      content = <BureauReportCentralPremium report={report} configuration={configuration} callbackFunction={() => setView(BureauGenerateViewType.INITIAL)} />
    }
  }

  return (
    <div style={{backgroundColor:'transparent',height:'100%'}}>
        <section className='idVerifyHead'>
            <section className='idVerifyContent'>
                <span><img src={crbLogoHead} alt=''/></span>
                <p>Credit Bureau</p>
            </section>
        </section>

        <div className='roofCrb'>
              <span 
                  className={viewReport?'span-view-report':'span-view-history'} 
                  onClick={()=>{setViewReport(false);setView(BureauGenerateViewType.INITIAL)}}>
                  History
              </span>
              <span>/</span>
              <span className={viewReport?'span-view-history':'span-view-report'}>View report</span>
        </div>

        <div className="bureau-main">
        <section style={{
                margin:'15px auto'
              }}>
            <section style={{fontSize:'24px',
                            color:"#1F2228",
                            fontWeight:"700",
                            marginBottom:"15px"}}>
            Bureau Data Check
            </section>
            <section style={{fontSize:'16px',
                            color:"#6A717D",
                            fontWeight:"400"}}>
            Tap any of the cards below to proceed with check
            </section>
        </section>
      <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} >
        <BureauCreditCheck field={fieldType} close={closeModal} accessToken={accessToken} statementKey={null}
          configuration={configuration} />
      </Modal>

      <Modal isOpen={crbModalIsOpen} onAfterOpen={afterOpenCrbModal} style={customStyles} >
        <GhanaCrbModal field={fieldType} 
                        
                        close={closeCrbModal} 
                        accessToken={accessToken} 
                        statementKey={null}
                        configuration={configuration} 
                        setViewReport={setViewReport}
                        />
      </Modal>

      <Modal isOpen={crbGetModalIsOpen} onAfterOpen={afterOpenCrbModal} onRequestClose={closeCrbGetModal} style={customStyles} >
        <GhanaCrbFetchModal field={fetchFields} 
                            close={closeCrbGetModal} 
                            setViewReport={setViewReport}
                            reportFormat={reportFormat}
                            />
      </Modal>

      <Modal isOpen={crbPostKenyaModalIsOpen} onAfterOpen={afterOpenCrbKenyaPostModal} style={customStyles} >
        <PostKenyaCrbModal field={fieldType} 
                        
                        close={closeCrbKenyaPostModal} 
                        accessToken={accessToken} 
                        statementKey={null}
                        configuration={configuration} 
                        setViewReport={setViewReport}
                        />
      </Modal>

      
      {content}
    </div>
    </div>
    
  )
}

export default CreditBureauStandAlone;


/**
 * <Table data={CrbHistoryMainSerializer(bureau.allBureauReports)} 
                                columns={theadersCrb} 
                                tableClass="bureau-all-reports-table" 

                              />
 */