import React from 'react'
import arrowToLeft from '../../../../../assests/icons/arrowToLeft.png'


function ScoreDefinitionInitial({toggleState,saveScoreCard,selectedIcon,unselectedIcon,customDecisionCard,setCustomDecisionCard,setToggleState,register,setSection,closeModal}) {

  const revertToLanding = ()=>{
    closeModal()
    //setSection('proceed')
  }
  return (
    <div>
        
        <p style={{color:"rgba(85, 87, 90, 1)",fontSize:"18px"}}>Define Score Card</p>
        <section style={{rowGap:"20px",padding:"20px",boxSizing:"border-box",display:'flex',flexDirection:"column",justifyContent:"space-around",borderRadius:"5px",backgroundColor:"white",marginTop:"20px"}}>
            <div style={{display:'flex',flexDirection:'column'}}> 
                <label style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Name</label>
                <input  style={{border:'2px solid rgba(223, 232, 255, 1)',outline:"none",height:"45px",paddingLeft:"30px"}} 
                        placeholder='input name' 
                        autocomplete="off" 
                        type='text'
                        {...register('scoreName', 
                            { 
                                required:true, 
                            })
                        }  
                />
                        
            </div>

            <div style={{display:'flex',flexDirection:'row',columnGap:'25px'}}> 
                <section>
                    <p style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Base Score</p>
                        <div style={{display:'flex',justifyContent:"left",columnGap:"15px"}}>
                            <section style={{display:'flex',alignItems:"center",justifyContent:"center",columnGap:'10px'}}>
                                <input style={{border:'2px solid rgba(223, 232, 255, 1)',
                                                outline:"none",height:"55px",width:"120px"
                                                ,paddingLeft:'5px'}} placeholder='0' 
                                                {...register('baseScore', 
                                                    { 
                                                        required:true, 
                                                    })
                                                }  
                                                autocomplete="off" type='NUMBER'  />
                            </section>
                        </div>
                </section>
                <section>
                    <p>Type</p>
                    <div style={{display:'flex',columnGap:"20px",marginTop:"10px"}}>
                        <section onClick={()=>setToggleState('RULES')} className={toggleState==='RULES'?'selectToggle':'untoggle'}>
                            <span><img src={toggleState==='RULES'?selectedIcon:unselectedIcon}/></span>
                            <span className='toggletext'>Decision-based</span>
                        </section>

                        <section onClick={()=>setToggleState('POINTS')} className={toggleState==='POINTS'?'selectToggle':'untoggle'}>
                            <span><img src={toggleState==='POINTS'?selectedIcon:unselectedIcon}/></span>
                            <span className='toggletext'>Point-based</span>
                        </section>
                            
                    </div>
                </section>
                
            </div>
            <section>
                <p>Score-Card Variable</p>
                <div style={{display:'flex',columnGap:"20px",marginTop:"10px"}}>
                    <section onClick={()=>setCustomDecisionCard(true)} className={customDecisionCard?'selectToggle':'untoggle'}>
                        <span><img src={customDecisionCard?selectedIcon:unselectedIcon}/></span>
                        <span className='toggletext'>Bank Statement Variable</span>
                    </section>

                    <section onClick={()=>setCustomDecisionCard(false)} className={!customDecisionCard?'selectToggle':'untoggle'}>
                        <span><img src={!customDecisionCard?selectedIcon:unselectedIcon}/></span>
                        <span className='toggletext'>Non-Bank Statement Variable</span>
                    </section>
                        
                </div>
            </section>
            <div style={{display:"flex",justifyContent:"flex-end",columnGap:"15px"}}>
              
                <section style={{display:'flex',alignItems:'center',justifyContent:'end'}}>
                    <div className='createParameter' onClick={()=>saveScoreCard()}>Save</div>
                </section>
            </div>
        </section>
            
    </div>
  )
}

export default ScoreDefinitionInitial