import React,{useState} from 'react'
import Utils from '../../../../../library/Utils'

function PromoComponentSlice({pData,dropIcon}) {
  const [showSlice,setShowSlice] = useState(false)
  return (
    <div style={{width:'100%',padding:'20px 0px'}}>
                <section style={{width:'100%',display:"flex",alignItems:'center',padding:'20px 0px'}}>
                    <span style={{width:"18%",paddingLeft:"15px",boxSizing:"border-box"}}>
                        {Utils.checkNull(pData.loanProvider)}
                    </span>
                    <span style={{width:"18%",paddingLeft:"15px",boxSizing:"border-box"}}>
                        {Utils.checkNull(pData?.account)}
                    </span>
                    <span style={{width:"18%",paddingLeft:"15px",boxSizing:"border-box"}}>
                        {Utils.checkNull(pData?.loanAmount)}
                    </span>
                    <span style={{width:"18%",paddingLeft:"15px",boxSizing:"border-box"}}>
                        {Utils.checkNull(pData?.overdueAmount)}
                    </span>
                    <span style={{width:"18%",paddingLeft:"15px",boxSizing:"border-box"}}>
                        {Utils.checkNull(pData?.outstandingBalance)}
                    </span>

                    <span onClick={()=>setShowSlice(!showSlice)}>
                        <img src={dropIcon}/>
                    </span>
                </section>

                <>{showSlice&&
                <section style={{width:'100%',display:"flex",alignItems:'center',padding:'20px 0px'}}>
                    <div style={{width:"20%",paddingLeft:"15px",boxSizing:"border-box",display:"flex",flexDirection:"column"}}>
                        <span>Number of Performing</span>
                        <span style={{fontWeight:"700"}}>{Utils.checkNull(pData.noOfPerforming)}</span>
                        
                    </div>
                    <div style={{width:"20%",paddingLeft:"15px",boxSizing:"border-box",display:"flex",flexDirection:"column"}}>
                        <span>Number of Non Performing</span>
                        <span style={{fontWeight:"700"}}>{Utils.checkNull(pData.noOfNonPerforming)}</span>
                        
                    </div>
                    <div style={{width:"20%",paddingLeft:"15px",boxSizing:"border-box",display:"flex",flexDirection:"column"}}>
                        <span>Loan Count</span>
                        <span style={{fontWeight:"700"}}>{Utils.checkNull(pData.count)}</span>
                        
                    </div>
                    <div style={{width:"20%",paddingLeft:"15px",boxSizing:"border-box",display:"flex",flexDirection:"column"}}>
                        <span>Performance Status</span>
                        <span style={{fontWeight:"700"}}>{Utils.checkNull(pData.performanceStatus)}</span>
                        
                    </div>
                    <div style={{width:"20%",paddingLeft:"15px",boxSizing:"border-box",display:"flex",flexDirection:"column"}}>
                        <span>Status</span>
                        <span style={{fontWeight:"700"}}>{Utils.checkNull(pData.status)}</span>
                        
                    </div>
                </section>}</>
            </div>
  )
}

export default PromoComponentSlice