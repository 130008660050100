import React,{useEffect, useState} from 'react'
import './defineParameters.css'
import cardErrorIcon from '../../../../assests/icons/amlEmpty.png'
import { defineCustomParameters,selectSelfServiceState} from '../../../../redux/Slices/SelfServiceDecision'
import { useSelector,useDispatch } from 'react-redux'
import { useForm } from "react-hook-form";
import DefineParameterMode from './DefineParametersContents/DefineParameterMode'
import Spinner from '../../../../svg/Spinner/Spinner'
import { PostDataStatus } from '../../../../library/Variables'
import DefineParameterSuccess from './DefineParametersContents/DefineParameterSuccess'
import { defineCustomParametersOptions } from '../../../../redux/Slices/SelfServiceDecision'
import Utils from '../../../../library/Utils'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function DefineParameters({accessToken,closeModal,setShowCardCreated,setParamName,setMainParameterKey,setShowScoreCard,setParamMetaData}) {
    
    const dispatch = useDispatch()
    const [isComputed,setIsComputed] = useState(false)
    const { register,getValues,setValue, formState: { errors }, handleSubmit,trigger, reset } = useForm()
    const [options,setOptions] = useState([{key:'Option 1',value:""},{key:'Option 2',value:''}])

    const [displayType,setDisplayType] = useState('')
    const navigate = useNavigate()
    const saveScoreCard = ()=>{
        const allFields = getValues()
        const cardObj = {
                    ...allFields,
                    displayType:displayType,
                    isComputed:isComputed}
        setParameterState(cardObj)

        if(!Utils.isFieldEmpty(cardObj.name) && !Utils.isFieldEmpty(displayType) 
            && !Utils.isFieldEmpty(cardObj.isComputed)){
                if(setParamName){
                    setParamName(cardObj.name)
                }
                
                dispatch(defineCustomParameters({data:cardObj,accessToken}))
        }

        else{
            toast.info('Fill all fields before submitting.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
        }
        
      
    }


    const [parameterState,setParameterState] = useState()
    const selfServiceState = useSelector(selectSelfServiceState)
    const [ParameterDisplayed,setParameterDisplayed] = useState()
    const rules = [{labelOne:'Name',
                    titleOne:'Rule 1',
                    labelTwo:'Select Parameter',
                    titleTwo:'Select Parameter'},
                    {labelOne:'Rule Points',
                    titleOne:'input Point',
                    labelTwo:'Action',
                    titleTwo:'Select Action'},
                    {labelOne:'Value',
                    titleOne:'input Value',
                    labelTwo:'Passing Sentiments',
                    titleTwo:'Select Passing Sentiments'}
                ]
    const scoreDefFailure = <section className='successHoldBody'>
                                <span className='imgFlex'>
                                    <img src={cardErrorIcon}/>
                                </span>
                                <span className='cardInfo'>Parameter Creation Failed</span>
                                <span className='cardFailed'>{Utils.isFieldEmpty(selfServiceState.customParametersErrorMessage)?
                                'Something Went wrong.':selfServiceState.customParametersErrorMessage}</span>

                                <section style={{display:"flex",
                                                alignItems:"center",
                                                justifyContent:"center",
                                                width:"100%"}}>
                                    <span onClick={()=>closeModal()} className='button'>
                                        Close
                                    </span>
                                </section>
                                    
                            </section>

    useEffect(()=>{
        if(selfServiceState.customParametersCreationStatus===PostDataStatus.INITIAL){
            setParameterDisplayed(<DefineParameterMode  setIsComputed={setIsComputed} 
                                                        register={register} 
                                                        saveScoreCard={saveScoreCard} 
                                                        accessToken={accessToken}
                                                        setDisplayType={setDisplayType}
                                                        setOptions={setOptions}
                                                        displayType={displayType}
                                                        closeModal={closeModal}
                                                        />)
        }

        else if(selfServiceState.customParametersCreationStatus===PostDataStatus.FETCHING){
            setParameterDisplayed(<Spinner/>)
        }

        else if(selfServiceState.customParametersCreationStatus===PostDataStatus.SUCCESS){
            let Options;
            if(selfServiceState.parameterOptionsStatus === PostDataStatus.INITIAL && displayType==='SELECT'){
                if(displayType==='SELECT'){
                    let arrayObj = []
                    options.map(item=>{
                        if(!Utils.isFieldEmpty(item.value)){
                            arrayObj.push({"value":item.value})
                        }
                    })

                    const dataHolder = {
                                        "customParameterKey":selfServiceState.customParametersState.key,
                                        "options":arrayObj
                                       }
                    dispatch(defineCustomParametersOptions({data:dataHolder,accessToken}))
                }
                const newDisplay = <Spinner/>
                setParameterDisplayed(newDisplay)
               
            }

            else if (selfServiceState.parameterOptionsStatus === PostDataStatus.FETCHING && displayType==='SELECT'){
                const newDisplay = <Spinner/>
                setParameterDisplayed(newDisplay)
                            
            }

            else if (selfServiceState.parameterOptionsStatus === PostDataStatus.SUCCESS && displayType==='SELECT'){
                const newDisplay = 
                                <div style={{display:'flex',justifyContent:"space-between",alignItems:"start",columnGap:"20px"}}>
                                    <DefineParameterSuccess parameterState={parameterState}
                                                            closeModal={closeModal}
                                                            setParamMetaData={setParamMetaData}
                                                            setShowScoreCard={setShowScoreCard}
                                                            setMainParameterKey={setMainParameterKey}
                                    />
                                   
                                </div>
                setParameterDisplayed(newDisplay)
                if(setShowCardCreated){
                    setShowCardCreated(false)
                }
            }

            else if (selfServiceState.parameterOptionsStatus === PostDataStatus.INITIAL && displayType !=='SELECT'){
                const newDisplay = 
                                <div style={{display:'flex',justifyContent:"space-between",alignItems:"start",columnGap:"20px"}}>
                                    <DefineParameterSuccess parameterState={parameterState}
                                                            closeModal={closeModal}
                                                            setShowScoreCard={setShowScoreCard}
                                                            setMainParameterKey={setMainParameterKey}
                                                            setParamMetaData={setParamMetaData}
                                    />
                                   
                                </div>
                setParameterDisplayed(newDisplay)
                if(setShowCardCreated){
                    setShowCardCreated(false)
                }
            }
            
        }

        else if(selfServiceState.customParametersCreationStatus===PostDataStatus.FAILURE){
            setParameterDisplayed(scoreDefFailure)
        }


    },[selfServiceState.customParametersCreationStatus, selfServiceState.parameterOptionsStatus,displayType])
  return (
    <div style={{padding:'0px 15px',boxSizing:"border-box",maxHeight:'450px',minHeight:'300px',overflowY:'scroll',position:"relative"}}>
        {ParameterDisplayed}
    </div>
  )
}

export default DefineParameters

/**
 * Options =   <div style={{display:displayType==='SELECT'?'block':'none',
                                width:"65%",marginTop:'30px'}}>
                                <DefineSelectOptions  
                                    response={selfServiceState.customParametersState} 
                                    accessToken={accessToken}
                                />
                            </div>
 */