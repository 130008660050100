import React,{useEffect, useState} from 'react'
import { useForm } from "react-hook-form";
import NewRule from './rulesComponents/NewRule';
import arrowToLeft from '../../../assests/icons/arrowToLeft.png'
import './defineRules.css'
import { getSelfServiceCustomParams,selectSelfServiceState,createRules } from '../../../redux/Slices/SelfServiceDecision';
import { useDispatch,useSelector } from 'react-redux'
import Spinner from '../../../svg/Spinner/Spinner';
import { FetchDataStatus, PostDataStatus } from '../../../library/Variables';
import Utils from '../../../library/Utils';
import { useNavigate } from 'react-router';

function DefineRulesInitials({accessToken}) {
    const { register,getValues,setValue, formState: { errors }, handleSubmit,trigger, reset } = useForm()
    const [rulesLength,setRulesLength] = useState([{key:'1',content:{}}])
    const dispatch = useDispatch()
    const selfState = useSelector(selectSelfServiceState)
    const [mainContent,setMainContent] = useState()
    const [scoreCard,setScoreCard] = useState()
    const [definitionRules,setDefinitionRules] = useState([])
    const [submittable,setSubmittable] = useState(false)
    const [storeScoreCardDet,setScoreCardDet] = useState([])
    const [triggerSaveRules,setTriggerSaveRules] = useState(false)
    const [triggerSubmit,setTriggerSubmit] = useState(false)
    const navigate = useNavigate()


    const createSaveRules = ()=>{
          setTriggerSaveRules(true)
    }
    

    useEffect(()=>{
        dispatch(getSelfServiceCustomParams({accessToken}))

        if(selfState.selfServiceParametersStatus === FetchDataStatus.SUCCESS){
            setScoreCardDet(selfState?.selfServiceDefinitionList)
        }
        
    },[])

    useEffect(()=>{
        if(!Utils.isFieldEmpty(scoreCard)){
            setSubmittable(true)
        }
        else{
            setSubmittable(false)
        }
    },[scoreCard])

    const submitAllRules = ()=>{
        const filledRules = {"scoreCardDefinitionKey": parseInt(scoreCard),
        "scoreCardDefinitionRules":definitionRules}

        dispatch(createRules({data:filledRules,accessToken}))
   
    }

    const addRuleCard = ()=>{
        const newRuleCard = [...rulesLength,{key:`${rulesLength.length+1}`,content:{}}]
        setRulesLength(()=>newRuleCard)
    } 

    useEffect(()=>{
        if(triggerSubmit){
            submitAllRules()
        }
    },[triggerSubmit])

    const ruleLoaded = 
                        <section style={{padding:'30px 55px',boxSizing:"border-box"}}>

                            <section onClick={()=>navigate('/DecisionEngineSelfService')} style={{display:'flex',marginBottom:'30px',
                                            cursor:'pointer',alignItems:'center',justifyContent:'left',
                                            columnGap:'5px',marginBottom:'35px'}}>
                                <span style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                    <img src={arrowToLeft}/></span>
                                <span style={{color:'rgba(31, 34, 40, 1)',fontSize:"16px"}}>Back to History</span>
                            </section>
                            <section style={{display:'flex',alignItems:'center',justifyContent:"space-between"
                                    ,marginBottom:'25px'}}>
                                <div style={{display:'flex',flexDirection:'column',rowGap:"10px"}}>
                                    <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"24px",fontWeight:'700'}}>
                                        Decision Engine
                                    </span>
                                    <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"20px"}}>
                                        Customize your Decision Engine Rules
                                    </span>
                                </div>
        
                            </section>

                            <section style={{display:'flex',flexDirection:"column",width:"350px",marginBottom:'30px',alignItems:"start",justifyContent:"center",columnGap:"10px"}}>
                                <label style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px",textAlign:"left"}}>Select ScoreCard</label>
                    
                                <select  style={{display:'flex',alignItems:"center",height:"45px",width:"100%",
                                        border:"1px solid rgba(223, 232, 255, 1)",justifyContent:"space-between",
                                        padding:"0px 7px",outline:"none"}} 
                                        onChange={(e) => setScoreCard(e.target.value)}
                                >
                                    <option value="">Select an option</option>
                                        {storeScoreCardDet.map((element, i) => {
                                            return <option  value={element.scorecardDefinitionKey} key={i}>
                                                        {element.scorecardDefinitionName}
                                    </option>
                                })}
                        
                                </select>
                
                            </section>
                            <div >
                                {rulesLength.map((ruleContent,index)=><NewRule ruleIndex={index} setRulesLength={setRulesLength} 
                                                rulesLength={rulesLength} ruleContent={ruleContent} 
                                                paramData={selfState?.selfServiceParameterData}
                                                scoreCardList={selfState?.selfServiceDefinitionList} definitionRules={definitionRules}
                                                setDefinitionRules={setDefinitionRules} scoreCard={scoreCard} 
                                                accessToken={accessToken} setScoreCardDet={setScoreCardDet}
                                                storeScoreCardDet={storeScoreCardDet}
                                                triggerSaveRules={triggerSaveRules}
                                                setTriggerSubmit={setTriggerSubmit}
                                                />)}      
                            </div>
                            <section style={{display:'flex',alignItems:'center',justifyContent:'end',columnGap:'15px'}}>
                                
                                <div className='createParameter' onClick={()=>createSaveRules()}>Submit</div>
                            </section>
                        </section>
    useEffect(()=>{
        if(selfState.selfServiceParametersStatus === FetchDataStatus.INITIAL){
            setMainContent(<Spinner/>)
        }

        else if(selfState.selfServiceParametersStatus === FetchDataStatus.FETCHING){
            setMainContent(<Spinner/>)
        }

        else if(selfState.selfServiceParametersStatus === FetchDataStatus.SUCCESS){
            setMainContent(ruleLoaded)
        }
        if(!Utils.isFieldEmpty(setScoreCardDet(selfState?.selfServiceDefinitionList))){
            setScoreCardDet(selfState?.selfServiceDefinitionList)
        }

        if(selfState.parameterRulesStatus === PostDataStatus.FETCHING){
            setMainContent(<Spinner/>)
        }
        if(selfState.parameterRulesStatus === PostDataStatus.SUCCESS){
            let show = 
            <section style={{padding:'30px 55px',boxSizing:"border-box"}}>

                <section onClick={()=>navigate('/DecisionEngineSelfService')} style={{display:'flex',marginBottom:'30px',
                                cursor:'pointer',alignItems:'center',justifyContent:'left',
                                columnGap:'5px',marginBottom:'35px'}}>
                    <span style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <img src={arrowToLeft}/></span>
                    <span style={{color:'rgba(31, 34, 40, 1)',fontSize:"16px"}}>Back to History</span>
                </section>
                <section style={{display:'flex',alignItems:'center',justifyContent:"space-between"
                        ,marginBottom:'25px'}}>
                    <div style={{display:'flex',flexDirection:'column',rowGap:"10px"}}>
                        <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"24px",fontWeight:'700'}}>
                            Decision Engine
                        </span>
                        <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"20px"}}>
                            Customize your Decision Engine Rules
                        </span>
                    </div>

                </section>
            

                <section style={{padding:'20px 0px',width:"75%",margin:"0px auto"}}>
                    <p style={{color:"rgba(85, 87, 90, 1)",fontSize:"18px",textAlign:"center"}}>Defined Rule</p>
                    <section style={{minHeight:"350px",padding:"30px 25px",boxSizing:"border-box",display:'flex',alignItems:"center",flexDirection:"column",rowGap:'20px',justifyContent:"space-between",borderRadius:"5px",backgroundColor:"white",marginTop:"20px",boxShadow:" 0 3px 6px rgba(0, 0, 0, .1)"}}>
                        <p style={{textAlign:"center",fontSize:"35px",fontWeight:"700"}}>Successful!!!</p>
                        <p style={{color:'rgba(54, 60, 79, 1)',fontWeight:"700",fontSize:"18px"}}>Your Rule has been Created Successfully.</p>
                    

                    
                    </section>
                </section>
            </section>
            
            setMainContent(show)
        }

        if(selfState.parameterRulesStatus === PostDataStatus.FAILURE){
            let show = 
            <section style={{padding:'30px 55px',boxSizing:"border-box"}}>

                <section onClick={()=>navigate('/DecisionEngineSelfService')} style={{display:'flex',marginBottom:'30px',
                                cursor:'pointer',alignItems:'center',justifyContent:'left',
                                columnGap:'5px',marginBottom:'35px'}}>
                    <span style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <img src={arrowToLeft}/></span>
                    <span style={{color:'rgba(31, 34, 40, 1)',fontSize:"16px"}}>Back to History</span>
                </section>
                <section style={{display:'flex',alignItems:'center',justifyContent:"space-between"
                        ,marginBottom:'25px'}}>
                    <div style={{display:'flex',flexDirection:'column',rowGap:"10px"}}>
                        <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"24px",fontWeight:'700'}}>
                            Decision Engine
                        </span>
                        <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"20px"}}>
                            Customize your Decision Engine Rules
                        </span>
                    </div>

                </section>
            

                <section style={{padding:'20px 0px',width:"75%",margin:"0px auto"}}>
                    <p style={{color:"rgba(85, 87, 90, 1)",fontSize:"18px",textAlign:"center"}}>Rule Definition Failed!</p>
                    <section style={{minHeight:"350px",padding:"30px 25px",boxSizing:"border-box",display:'flex',alignItems:"center",flexDirection:"column",rowGap:'20px',justifyContent:"space-between",borderRadius:"5px",backgroundColor:"white",marginTop:"20px",boxShadow:" 0 3px 6px rgba(0, 0, 0, .1)"}}>
                        <p style={{textAlign:"center",color:"red",fontSize:"35px",fontWeight:"700"}}>ERROR!!!</p>
                        <p style={{color:'rgba(54, 60, 79, 1)',fontWeight:"700",fontSize:"18px"}}>Your Rule has not been Created.</p>
                    

                    
                    </section>
                </section>
            </section>
            
            setMainContent(show)
        }
        
    },[selfState,rulesLength,definitionRules,scoreCard,storeScoreCardDet])
    
  return (
    <section  >
        {mainContent}
    </section>
  )
}

export default DefineRulesInitials