import React,{useEffect, useState} from 'react'
import '../DefineScoreCard/definescorecard.css'
import RulesModal from '../../Modals/RulesModal'
import Modal from "react-modal";
import Spinner from '../../../../svg/Spinner/Spinner'
import { useDispatch,useSelector } from 'react-redux'
import { selectSelfServiceState,getScoreCardRulesByKey,resetParameterByKey } from '../../../../redux/Slices/SelfServiceDecision'
import { FetchDataStatus, PostDataStatus } from '../../../../library/Variables'
import RuleList from '../DefineScoreCard/DefinitionComponents/RuleList'
import arrowToLeft from '../../../../assests/icons/arrowToLeft.png'

import 'react-toastify/dist/ReactToastify.css';
import Utils from '../../../../library/Utils';



function RulesAndParameters({setSection,accessToken,paramName,setShowCardCreated,mainParameterKey,mainScoreCardKey,paramMetaData}) {
    const selfScoreDef = useSelector(selectSelfServiceState)
    const dispatch = useDispatch()

    const [parameterType,setParameterType] = useState(selfScoreDef.customParametersState?.displayType)
    const [RuleContent,setRuleContent] = useState(<Spinner/>)
    const [createParamIsOpen, setcreateParamIsOpen] = useState(false);
    const [createRuleIsOpen, setcreateRuleIsOpen] = useState(false);

    
      function afterOpenModal() { }
    
   

        function closeRuleCreateModal() {
            setcreateRuleIsOpen(false)

        //dispatch(resetDownload());
        }
    
      const customStyles = {
        content: {
          transition: '0.125s ease-in-out',
          transform: 'scale(1)',
          height: '33%',
          width: '80%',
          top: '33%',
          left: '33%',
          padding: '20px',
          boxSizing:'border-box',
        },
        overlay: {
          zIndex: 1000,
          position: 'absolute'
        }
      };


  

    let cardObj
   

   /**
    *  useEffect(()=>{
        if(selfScoreDef.selfServiceCardNameStatus === PostDataStatus.SUCCESS){
            dispatch(getSelfServiceCustomParams({accessToken}))
        }
        
    },[selfScoreDef.selfServiceCardNameStatus])
    */


    useEffect(()=>{
        
        if(selfScoreDef.selfServiceGetRulesByKeyStatus === FetchDataStatus.INITIAL ){
            if(Utils.isFieldEmpty(selfScoreDef.customParametersState?.key)){
                dispatch(getScoreCardRulesByKey({accessToken,cardKey:selfScoreDef.selfServiceCardRepsonse?.key,parameterKey:paramMetaData?.key}))
            }
            else{
                dispatch(getScoreCardRulesByKey({accessToken,cardKey:selfScoreDef.selfServiceCardRepsonse?.key,parameterKey:selfScoreDef.customParametersState?.key}))
            }
            
        }
        
    },[selfScoreDef.selfServiceGetRulesByKeyStatus])


    useEffect(()=>{
        if(selfScoreDef.selfServiceGetRulesByKeyStatus=== FetchDataStatus.INITIAL){
            setRuleContent(<Spinner/>)
        }

        else if(selfScoreDef.selfServiceGetRulesByKeyStatus=== FetchDataStatus.FETCHING){
            setRuleContent(<Spinner/>)
        }

        else if(selfScoreDef.selfServiceGetRulesByKeyStatus=== FetchDataStatus.SUCCESS){
            const Content = <RuleList setSection={setSection} 
                                    selfScoreDef={selfScoreDef}
                                    setcreateParamIsOpen={setcreateParamIsOpen}
                                    setcreateRuleIsOpen={setcreateRuleIsOpen}
                                    />
            setRuleContent(Content)
        }

        else if(selfScoreDef.selfServiceGetRulesByKeyStatus=== FetchDataStatus.FAILURE){
            const Content = <div className='ParamBody'>
                                <p className='ItalicContent'>
                                        Something Went Wrong.
                                </p>

                                <p style={{textAlign:'center',
                                            fontSize:'15px',
                                            marginTop:'10px'}}>
                                            {selfScoreDef.selfServiceGetRulesByKeyErrorMessage}
                                </p>
                            </div>
            setRuleContent(Content)
        }

    },[selfScoreDef.selfServiceGetRulesByKeyStatus])


  return (
    <div>
        <section onClick={()=>{setShowCardCreated(true);dispatch(resetParameterByKey())}} style={{display:'flex',cursor:'pointer',alignItems:'center',justifyContent:'left',columnGap:'5px',marginBottom:'35px'}}>
            <span style={{display:'flex',alignItems:'center',justifyContent:'center'}}><img src={arrowToLeft}/></span>
            <span style={{color:'rgba(31, 34, 40, 1)',fontSize:"16px"}}>Back to History</span>
        </section>
        <section style={{display:'flex',alignItems:'center',justifyContent:"space-between"}}>
            <div style={{display:'flex',flexDirection:'column',rowGap:"10px"}}>
                <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"24px",fontWeight:'700'}}>
                    Decision Engine
                </span>
                <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"20px"}}>
                    Customize your Decision Engine
                </span>
            </div>
          
        </section>

        <section style={{margin:'30px 0px',padding:'20px 0px'}}>
            <p style={{color:"rgba(85, 87, 90, 1)",fontSize:"18px",textAlign:"center"}}>Preview Parameter</p>
            <>
                {!Utils.isFieldEmpty(paramMetaData)? <section style={{height:"300px",rowGap:"10px",padding:"10px 25px",boxSizing:"border-box",display:'flex'
                            ,flexDirection:"column",justifyContent:"space-around",alignItems:'center',borderRadius:"5px"
                            ,backgroundColor:"white",marginTop:"20px",boxShadow:" 0 3px 6px rgba(0, 0, 0, .1)"}}>

                            <p style={{textAlign:"center",fontSize:"35px",fontWeight:"700"}}>{paramMetaData?.name}</p>
                            <div style={{display:'flex'}}> 
                                <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Name: &nbsp; &nbsp;</span>
                                <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"700",fontSize:"18px"}}>{paramMetaData?.name}</span>
                                    
                            </div>

                            <div style={{display:'flex',alignItems:'center',justifyContent:"center"}}> 
                                <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Type: &nbsp; &nbsp;</span>
                                <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"700",fontSize:"18px"}}>{paramMetaData?.type}</span>
                                    
                            </div>



                    </section>:
                    <section style={{height:"300px",rowGap:"10px",padding:"10px 25px",boxSizing:"border-box",display:'flex'
                        ,flexDirection:"column",justifyContent:"space-around",alignItems:'center',borderRadius:"5px"
                        ,backgroundColor:"white",marginTop:"20px",boxShadow:" 0 3px 6px rgba(0, 0, 0, .1)"}}>

                        <p style={{textAlign:"center",fontSize:"35px",fontWeight:"700"}}>{paramName}</p>
                        <div style={{display:'flex'}}> 
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Name: &nbsp; &nbsp;</span>
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"700",fontSize:"18px"}}>{paramName}</span>
                                
                        </div>

                        <div style={{display:'flex',alignItems:'center',justifyContent:"center"}}> 
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Type: &nbsp; &nbsp;</span>
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"700",fontSize:"18px"}}>{selfScoreDef.customParametersState?.displayType}</span>
                                
                        </div>



                </section>}
            </>
            
        </section>

        {selfScoreDef.selfServiceCardNameStatus === PostDataStatus.SUCCESS && RuleContent}

     

        <Modal isOpen={createRuleIsOpen} 
               onAfterOpen={afterOpenModal} 
               style={customStyles}
               contentLabel="Create Rules." >
            <RulesModal closeModal={closeRuleCreateModal}
                              accessToken={accessToken}
                              mainScoreCardKey={mainScoreCardKey}
                              mainParameterKey={mainParameterKey}
                              paramName={paramName}
                              parameterType={parameterType}
                              />
        </Modal>

   
        
        
    </div>
  )
}

export default RulesAndParameters

   /**
         <Modal isOpen={selfScoreCardIsOpen} 
               onAfterOpen={afterOpenModal} 
               style={customStyles}
               contentLabel="Create ScoreCard Name." >
               <CreateSelfScoreCard setSection={setSection} 
                                accessToken={accessToken} 
                                closeModal={closeSelfScoreCardModal}
                                setcreateParamIsOpen={setcreateParamIsOpen}
                                
               />
        </Modal>
         */