import React,{useState,useEffect} from 'react'
import addIcon from '../../../../../assests/svg-icons/addParams.svg'
import deleteOptionIcon from '../../../../../assests/svg-icons/DeleteOption.svg'
import checkBoxIcon from '../../../../../assests/icons/checkBoxed.png'
import unCheckBoxedIcon from '../../../../../assests/icons/unCheckBoxed.png'
import { getSelfServiceCustomParams,selectSelfServiceState } from '../../../../../redux/Slices/SelfServiceDecision'
import OptionInputComponent from './InputComponent'
import Spinner from '../../../../../svg/Spinner/Spinner'
import { FetchDataStatus } from '../../../../../library/Variables'
import { useSelector,useDispatch } from 'react-redux'

function DefineParameterMode({register,saveScoreCard,setIsComputed,accessToken,setDisplayType,setOptions,displayType,closeModal}) {
    const [addParameter,setParameter] = useState(false)
    const selfServiceState = useSelector(selectSelfServiceState)
    const [maxNumber,setMaxNumber] = useState(2)
    const [showDelete,setShowDelete] = useState(false)
    const [selectedAction, setSelectedAction] = useState('');
    const TypesOfInput = ['TEXT','NUMBER','SELECT','CURRENCY']
    const firstAction = ['ADD','SUBTRACT','MULTIPLY','DIVIDE']
    const [optionLength,setOptionLength] = useState([{key:'Option 1',value:""},{key:'Option 2',value:''}])
    const dispatch = useDispatch()


    useEffect(()=>{
        if(selfServiceState.selfServiceParametersStatus !== FetchDataStatus.SUCCESS){
            dispatch(getSelfServiceCustomParams({accessToken}))
        }
    },[])

    useEffect(()=>{
        setOptions(optionLength)
        if(optionLength.length>maxNumber){
            setMaxNumber(optionLength.length)
        }
         else if(optionLength.length<maxNumber){
            setMaxNumber(maxNumber+1)
         }

        if(optionLength.length>2){
            setShowDelete(true)
        }
        else{
            setShowDelete(false)
        }
    },[optionLength])


    useEffect(()=>{
        
        if(addParameter){
            setIsComputed(true)
        }
        else{
            setIsComputed(false)
        }
    },[addParameter])

    const deleteItem = (key) => {
        setOptionLength((prevItems) => prevItems.filter((_, index) => _.key !== key));
      };

    
  const handleSelectChange = (event) => {
    setSelectedAction(event.target.value);
    setDisplayType(event.target.value)
  };

  return (
        <section style={{padding:'20px 0px'}}>{
            selfServiceState.selfServiceParametersStatus === FetchDataStatus.SUCCESS?
            <>
            <p style={{color:"rgba(85, 87, 90, 1)",fontSize:"18px"}}>Define Parameters</p>
            <section style={{minHeight:"350px",padding:"30px 25px",boxSizing:"border-box",display:'flex',flexDirection:"column",rowGap:'20px',justifyContent:"space-between",borderRadius:"5px",backgroundColor:"white",marginTop:"20px"}}>
                <div style={{display:'flex',flexDirection:'column'}}> 
                    <label style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Parameter Name</label>
                    <input  style={{border:'2px solid rgba(223, 232, 255, 1)',height:"45px",paddingLeft:"30px"}} 
                            placeholder='input name' autocomplete="off" type='text'
                            {...register('name', 
                                { 
                                    required:true, 
                                })
                            }   
                            
                    />
                               
                </div>

                <div style={{display:'flex',flexDirection:'column'}}> 
                
                    <div style={{display:'flex',justifyContent:"left",columnGap:"15px"}}>
                        <section style={{display:'flex',flexDirection:"column",alignItems:"start",justifyContent:"center",columnGap:"10px"}}>
                            <label style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px",textAlign:"left"}}>Input Type</label>
                            
                            <select  onChange={handleSelectChange}
                                     style={{display:'flex',alignItems:"center",height:"45px",width:"300px",
                                            border:"1px solid rgba(223, 232, 255, 1)",justifyContent:"space-between",
                                            padding:"0px 7px",outline:"none"}} 
                            >
                                <option value="">Select an option</option>
                                {TypesOfInput.map((element, i) => {
                                    return <option value={element} key={i}>{element}</option>
                                })}
                            </select>
                           
                        </section>
                       
                    </div>
                </div>

                {selectedAction==='SELECT' && <section>
                    <div className='OptionClass'>Options</div>
                    <section className='optionGrid'>
                    {optionLength.map((name, i) => {
                     return (   <div style={{display:'flex',flexDirection:'column'}}> 
                        
                        <div className='optionHolderDiv'>
                            <OptionInputComponent index={i} 
                                                  setOptionLength={setOptionLength}
                                                  optionLength={optionLength}
                                                  />
                            {showDelete && <img src={deleteOptionIcon} 
                                 className='imgHoldSpan'
                                 onClick={()=>deleteItem(name.key)}
                                 />
                            }
                        </div>
                               
                    </div>)
                    }) }

                    </section>
                    <section className='optionButton'>
                        <img src={addIcon} className='imgHoldSpan'/>
                        <div onClick={()=>setOptionLength([...optionLength,{key:`option ${maxNumber+1}`,value:''}])}>
                            Add Options
                        </div>
                    </section>
                </section>}
                <div >
                    <section onClick={()=>{setParameter(!addParameter)}} className='addParams' >
                        <span className='imageHolder'>
                            <img src={addParameter?checkBoxIcon:unCheckBoxedIcon}/>
                        </span>
                        <span>
                            Combine Existing Parameters
                        </span>
                    </section>

                    {addParameter && <div  className='optionGrid'>
                        <section style={{display:'flex',flexDirection:"column",alignItems:"start",justifyContent:"center",columnGap:"10px"}}>
                            <label style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px",textAlign:"left"}}>Parameter One</label>

                            <select  style={{display:'flex',alignItems:"center",height:"45px",width:"300px",
                                    border:"1px solid rgba(223, 232, 255, 1)",justifyContent:"space-between",
                                    padding:"0px 7px",outline:"none"}} {...register('field1', { required: true})} 
                            >
                                <option value="">Select an action</option>
                                {selfServiceState.selfServiceParameterData.map((element, i) => {
                                    return <option value={element.name} key={i}>{element.name}</option>
                                })}
                            </select>
                           
                        </section>

                        <section style={{display:'flex',flexDirection:"column",alignItems:"start",justifyContent:"center",columnGap:"10px"}}>
                            <label style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px",textAlign:"left"}}>Action</label>
                            
                            <select  style={{display:'flex',alignItems:"center",height:"45px",width:"300px",
                                    border:"1px solid rgba(223, 232, 255, 1)",justifyContent:"space-between",
                                    padding:"0px 7px",outline:"none"}} {...register('action', { required: true})} 
                            >
                                <option value="">Select an action</option>
                                {firstAction.map((element, i) => {
                                    return <option value={element} key={i}>{element}
                                    </option>
                                })}
                            </select>
                           
                        </section>

                        <section style={{display:'flex',flexDirection:"column",alignItems:"start",justifyContent:"center",columnGap:"10px"}}>
                            <label style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px",textAlign:"left"}}>Parameter Two</label>
                        
                            <select  style={{display:'flex',alignItems:"center",height:"45px",width:"300px",
                                    border:"1px solid rgba(223, 232, 255, 1)",justifyContent:"space-between",
                                    padding:"0px 7px",outline:"none"}} {...register('field2', { required: true})} 
                            >
                                <option value="">Select an action</option>
                                {selfServiceState.selfServiceParameterData.map((element, i) => {
                                    return <option value={element.name} key={i}>{element.name}</option>
                                })}
                            </select>
                           
                        </section>
                    </div>}
                    <div className='ParamButton'>    
                        <div className='cancelParameter' 
                             onClick={()=>closeModal()}>
                             Cancel
                        </div>
                        <div className='createParameter' 
                             onClick={()=>saveScoreCard()} >
                             Save
                        </div>
                     
                    </div>
                    
                </div>
            
            </section>
            </>:
            <Spinner/>}
        </section>
  )
}

export default DefineParameterMode