import React from 'react'
import Utils from '../../../../../library/Utils'
import dropIcon from '../../../../../assests/svg-icons/PerformanceDrop.svg'
import HistoryComponentSlice from './HistoryComponentSlice'


function LoanHistory({performanceData}) {
  const performanceHeader = ['Loan Provider','Account Number','Type','Currency','Loan Amount']
  return (
    <div style={{width:"100%",height:"auto",marginTop:"30px",backgroundColor:"white",padding:"30px",boxSizing:"border-box"}}>
        <section style={{color:"#2A38A4",fontSize:"24px",fontWeight:"700",margin:'20px 0px'}}>Loan History</section>

        <section style={{width:'100%',display:"flex",backgroundColor:'#EBF4FF',padding:'20px 0px'}}>
            {performanceHeader.map(el=>
            <span style={{width:'18%',color:'#130F26',fontWeight:"700",paddingLeft:"15px",boxSizing:"border-box"}}>
                {el}
            </span>)}
        </section>

        <section>
            {performanceData.map(pData=>
            <HistoryComponentSlice pData={pData} dropIcon={dropIcon}/>)}
                
        </section>
    </div>
  )
}

export default LoanHistory