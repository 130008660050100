import React,{useEffect, useState} from 'react'
import './preview.css'
import CreateSelfScoreCard from '../Modals/CreateSelfScoreCard'
import selectedIcon from '../../../assests/icons/concentricSelected.png'
import unselectedIcon from '../../../assests/icons/nonSelected.png'
import EditScoreCard from '../Modals/EditScoreCardModal'
import Spinner from '../../../svg/Spinner/Spinner'
import { useDispatch,useSelector } from 'react-redux'
import { selectSelfServiceState,getSelfServiceCustomParams,getSelfServiceCustomParamsByKey,resetEditScoreCard,resetParameterByKey} from '../../../redux/Slices/SelfServiceDecision'
import { FetchDataStatus,PostDataStatus } from '../../../library/Variables'
import ParameterList from '../CreateDecision/DefineScoreCard/DefinitionComponents/ParameterList'
import Utils from '../../../library/Utils'
import arrowToLeft from '../../../assests/icons/arrowToLeft.png'
import errorIcon from '../../../assests/icons/amlEmpty.png'
import RulesModal from '../Modals/RulesModal'
import DeleteParameterModals from '../Modals/DeleteParameterModals'
import DefineParameters from '../CreateDecision/DefineParameters/DefineParameters'
import Modal from "react-modal";


function ScoreCardPreview({setSection,accessToken,previewCard,key,mainScoreCardKey,mainParameterKey,setShowScoreCard,setParamMetaData,paramName,setPreviewCard,setParamName,setMainParameterKey}) {

    const dispatch = useDispatch()
    const [ParamContent,setParamContent] = useState(<Spinner/>)
    const [scoreCardKey,setScoreCardKey] = useState(previewCard.key)
    const [scoreCardBaseScore,setScoreCardBaseScore] = useState(previewCard.baseScore)
    const [parameterType,setParameterType] = useState('')


   
    const selfScoreDef = useSelector(selectSelfServiceState)
    const [selfScoreCardIsOpen, setSelfScoreCardIsOpen] = useState(false);

    const [createParamIsOpen, setcreateParamIsOpen] = useState(false);
    const [createRuleIsOpen, setcreateRuleIsOpen] = useState(false);
    const [deleteParamIsOpen, setDeleteParamIsOpen] = useState(false);
    const [editScoreCardIsOpen, setEditScoreCardIsOpen] = useState(false);
    const [deleteParamModalIsOpen, setDeleteParamModalIsOpen] = useState(false);

    const editScoreCard = ()=>{
        dispatch(resetEditScoreCard())
        setEditScoreCardIsOpen(true)
    }

    useEffect(()=>{
        dispatch(resetParameterByKey())
    },[])

    function openModal() {
        setSelfScoreCardIsOpen(true);
      }
    
      function afterOpenModal() { }
    
      function closeSelfScoreCardModal() {
              setSelfScoreCardIsOpen(false)
    
        //dispatch(resetDownload());
      }

      function closeParamCreateModal() {
        setcreateParamIsOpen(false)

        //dispatch(resetDownload());
        }

        function closeRuleCreateModal() {
            setcreateRuleIsOpen(false)

        //dispatch(resetDownload());
        }

        function closeEditScoreCardModal() {
            setEditScoreCardIsOpen(false)

        //dispatch(resetDownload());
        }

        function closeDeletParameterModal() {
            setDeleteParamModalIsOpen(false)

        //dispatch(resetDownload());
        }
    
      const customStyles = {
        content: {
          transition: '0.125s ease-in-out',
          transform: 'scale(1)',
          height: '33%',
          width: '80%',
          top: '33%',
          left: '33%',
          padding: '20px',
          boxSizing:'border-box',
        },
        overlay: {
          zIndex: 1000,
          position: 'absolute'
        }
      };



    useEffect(()=>{
    
            //dispatch(getSelfServiceCustomParams({accessToken}))
            if(selfScoreDef.selfServiceParametersByKeyStatus === FetchDataStatus.INITIAL){
                dispatch(getSelfServiceCustomParamsByKey({accessToken,key:previewCard.key}))
            }
            
        
        
    },[selfScoreDef.selfServiceParametersByKeyStatus])

    useEffect(()=>{
        if(selfScoreDef.selfServiceParametersByKeyStatus=== FetchDataStatus.INITIAL){
            setParamContent(<Spinner/>)
        }

        else if(selfScoreDef.selfServiceParametersByKeyStatus=== FetchDataStatus.FETCHING){
            setParamContent(<Spinner/>)
        }

        else if(selfScoreDef.selfServiceParametersByKeyStatus=== FetchDataStatus.SUCCESS){
            const Content = <ParameterList setSection={setSection} 
                                            selfScoreDef={selfScoreDef}
                                            setcreateParamIsOpen={setcreateParamIsOpen}
                                            setcreateRuleIsOpen={setcreateRuleIsOpen}
                                            setDeleteParamModalIsOpen={setDeleteParamModalIsOpen}
                                            accessToken={accessToken}
                                            name={previewCard.name}
                                            setShowScoreCard={setShowScoreCard}
                                            setParamMetaData={setParamMetaData}
                                            setMainParameterKey={setMainParameterKey}
                                            customDecisionCard={previewCard?.customCard}
                                            />
            setParamContent(Content)
        }

        else if(selfScoreDef.selfServiceParametersByKeyStatus=== FetchDataStatus.FAILURE){
            const Content = <section className='errorClassBox'>
                                <div className='imgHold'>
                                    <img src={errorIcon}/>
                                </div>
                                <div className='errorTitle'>
                                    <i>An error Occured</i>
                                </div>
                                <div className='errorMssg'>
                                    {selfScoreDef.selfServiceParameterByKeyErrorMessage}
                                </div>
                            </section>
            setParamContent(Content)
        }

    },[selfScoreDef.selfServiceParametersByKeyStatus])


  return (
    <div>
        <section onClick={()=>setSection('proceed')} style={{display:'flex',cursor:'pointer',alignItems:'center',justifyContent:'left',columnGap:'5px',marginBottom:'35px'}}>
            <span style={{display:'flex',alignItems:'center',justifyContent:'center'}}><img src={arrowToLeft}/></span>
            <span style={{color:'rgba(31, 34, 40, 1)',fontSize:"16px"}}>Back to History</span>
        </section>
        <section style={{display:'flex',alignItems:'center',justifyContent:"space-between"}}>
            <div style={{display:'flex',flexDirection:'column',rowGap:"10px"}}>
                <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"24px",fontWeight:'700'}}>
                    Decision Engine
                </span>
                <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"20px"}}>
                    Customize your Decision Engine
                </span>
            </div>
           
        </section>

        <section style={{margin:'30px 0px',padding:'20px 0px'}}>
            <div> 
                <p style={{color:"rgba(85, 87, 90, 1)",fontSize:"18px",textAlign:"center"}}>Preview Score Card</p>
                <section style={{height:"300px",rowGap:"10px",padding:"10px 25px",boxSizing:"border-box",display:'flex'
                        ,flexDirection:"column",justifyContent:"space-around",alignItems:'center',borderRadius:"5px"
                        ,backgroundColor:"white",marginTop:"20px",boxShadow:" 0 3px 6px rgba(0, 0, 0, .1)"}}>

                        <p style={{textAlign:"center",fontSize:"35px",fontWeight:"700"}}>{previewCard?.name}</p>
                        <div style={{display:'flex'}}> 
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Name: &nbsp; &nbsp;</span>
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"700",fontSize:"18px"}}>{previewCard?.name}</span>
                                
                        </div>

                        <div style={{display:'flex',alignItems:'center',justifyContent:"center"}}> 
                            <span style={{color:'rgba(54, 60, 79, 1)',fontWeight:"500",fontSize:"18px"}}>Type: &nbsp; &nbsp;</span>
                            <div style={{display:'flex',columnGap:"20px"}}>

                                <section className='selectToggle'>
                                    <span className='toggletext'>{previewCard?.type==='POINTS'?'Point-based':'Custom-based'}</span>
                                </section>
                                    
                            </div>
                                
                        </div>

                        <div className='openCardEdit'>
                            <section onClick={()=>editScoreCard()}>
                                Edit Score-Card
                            </section>
                        </div>



                </section>

        
            </div>
        </section>

        {ParamContent}
    
        <Modal isOpen={createParamIsOpen} 
               onAfterOpen={afterOpenModal} 
               style={customStyles}
               contentLabel="Create Custom Parameters." >
            <DefineParameters closeModal={closeParamCreateModal}
                              accessToken={accessToken}
                              setcreateRuleIsOpen = {setcreateRuleIsOpen}
                              setParamMetaData={setParamMetaData}
                              setShowScoreCard={setShowScoreCard}
                              setMainParameterKey={setMainParameterKey}
                              />
        </Modal>

        <Modal isOpen={createRuleIsOpen} 
               onAfterOpen={afterOpenModal} 
               style={customStyles}
               contentLabel="Create Rules." >
            <RulesModal closeModal={closeRuleCreateModal}
                              accessToken={accessToken}
                              customDecisionCard={previewCard?.customCard}
                              scoreCardKey={scoreCardKey}
                              setParamMetaData={setParamMetaData}
                              setMainParameterKey={setMainParameterKey}
                              setParamName={setParamName}
                              paramName={paramName}
                              mainScoreCardKey={mainScoreCardKey}
                              mainParameterKey={mainParameterKey}
                              setParameterType={setParameterType}
                              parameterType={parameterType}
                              />
        </Modal>

        <Modal isOpen={deleteParamIsOpen} 
               onAfterOpen={afterOpenModal} 
               style={customStyles}
               contentLabel="Create Rules." >
               <DeleteParameterModals closeModal={closeRuleCreateModal}
                                    accessToken={accessToken}
                                    />
        </Modal>

        <Modal isOpen={editScoreCardIsOpen} 
               onAfterOpen={afterOpenModal} 
               style={customStyles}
               contentLabel="Edit Score-card." >
               <EditScoreCard closeModal={closeEditScoreCardModal}
                                    accessToken={accessToken}
                                    scoreCardKey={scoreCardKey}
                                    name={previewCard.name}
                                    setPreviewCard={setPreviewCard}
                                    previewCard={previewCard}
                                    scoreCardBaseScore={scoreCardBaseScore}
                                    setScoreCardBaseScore={setScoreCardBaseScore}
                                    />
        </Modal>


    </div>
  )
}

export default ScoreCardPreview