import React,{useCallback,useMemo} from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch } from 'react-redux'
import { downloadOptionsTemplate } from '../../../redux/Slices/SelfServiceDecision'


function UploadParamOptionsSection({OptionFilesRejected,baseStyle,accessToken,activeStyle,acceptStyle,rejectStyle,setOptionFilesSelected,setOptionFilesRejected,OptionFileSelected,acceptedOptionFileItems,removeOptions,submitOption}) {
  
    const dispatch = useDispatch()
    const onDrop = useCallback(acceptedFiles => {
        const fileItem = {
          pdf: acceptedFiles,
          categoryId: "",
          name: '',
          statementType:''
          // accessToken: accessToken,
          // uniqueIdentifier: userId,
          // baseStatementKey: statementKey
        }
    
        setOptionFilesSelected([...OptionFileSelected, fileItem]);
        setOptionFilesRejected([]);
      }, [OptionFileSelected])
    
      const onDropRejected = useCallback(fileRejections => {
        setOptionFilesRejected([...OptionFilesRejected, ...fileRejections]);
        setOptionFilesSelected([]);
      }, [OptionFilesRejected])
    
      const {
        getRootProps,
        getInputProps,
        open,
        isDragActive,
        isDragAccept,
        isDragReject
      } = useDropzone({
        accept: { 'text/csv': ['.csv'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'], },
        filesizeBase: 1000,
        multiple: false,
        onDrop,
        onDropRejected
      });
      
      const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
      }), [
        isDragActive,
        isDragReject,
        isDragAccept
      ]);
  
    return (
    <div className="modal-dialog">
        <div className="modal-header">
            <h2 className="detail-header">Upload Parameter Options File</h2>
                   
        </div>
        <p style={{color:'grey',width:"100%"}}>(only upload this for select/dropdown parameters)</p>
        <div onClick={()=>dispatch(downloadOptionsTemplate({accessToken}))} style={{width:"100%",cursor:"pointer",color:'#1162DC'}}>Download parameter Options template</div>
        <div className="modal-body-container scrollbar">
            <div className="scrollable-content">
            <div {...getRootProps({style})} className="drop-container">
                <input {...getInputProps()} />
                <img src="/assets/icons/download-color.svg" alt="" />
                <p className="drop-caption">Drag "n" drop your file here, or <span className="detail-header"  >
                Open File Dialog</span>
                </p>
                <p className="drop-subcaption text-medium">FILE SUPPORTED: CSV</p>
            </div>

            {OptionFilesRejected.length > 0 &&
                <span className="ap-file-rejected-error-message error-msg">
                {OptionFilesRejected[0].errors[0].code === "file-too-large" 
                    ? "File is larger than 10MB"
                    : OptionFilesRejected[0].errors[0].message}
                </span>
            }
            {
            
                acceptedOptionFileItems.length > 0 ?
                <>
            
                <h4 className="text-medium">SELECTED FILE:</h4>
                {
                    OptionFileSelected?.map((file, id) => {
                    return (
                    <div className="ap-accepted-file-container" key={id}>
                        <ul>
                            <li key={file.pdf[0].path}>
                            <div className="ap-accepted-file-info">
                                <img src="/assets/icons/statement-color.svg" alt="" />
                                <div>
                                <h4 className="text-dark">{file.pdf[0].path}</h4>
                                <span className="text-medium">{file.pdf[0].size / 1000000} MB</span>
                                </div> 
                            </div>
                            <button onClick={() => removeOptions(file)} className="delete-pdf-button">
                                <img src="/assets/icons/delete-color.svg" alt="Delete" />
                            </button>
                            </li>
                        </ul>
                        

                    </div>
                    )
                    })
                }

                <div className="submit-container">
                    <button className="btn-submit button-solid" onClick={() => submitOption()}>Upload</button>
                </div>
                </>:''
            }
            </div>
        </div>
      </div>
  )
}

export default UploadParamOptionsSection