import React, {useEffect,useState} from 'react'
import { FetchDataStatus } from '../../../library/Variables'
import { deleteCustomParameters,resetParameterByKey } from '../../../redux/Slices/SelfServiceDecision'
import { useDispatch,useSelector } from 'react-redux'
import { selectSelfServiceState } from '../../../redux/Slices/SelfServiceDecision'
import Spinner from '../../../svg/Spinner/Spinner'


function DeleteParameterModals({name,closeModal,parameterKey,accessToken}) {
  const dispatch = useDispatch()
  const selfScoreDef = useSelector(selectSelfServiceState)
  const [paramUiState,setParamUiState] = useState()


  useEffect(()=>{
    if(selfScoreDef.deleteCustomParameterStatus=== FetchDataStatus.INITIAL){
      const data = <>
                      <section style={{margin:'10px 0px',padding:'0px 0px'}}>
                        <i>{`All rules associated with the ${name} parameter will be deleted. To proceed, click on Proceed else Cancel.`}</i>
                      </section>

                      <div style={{display:"flex",justifyContent:"flex-end",columnGap:"15px"}}>
                          <section style={{display:'flex',alignItems:'center',justifyContent:'end'}}>
                              <div className='cancelEditCard' onClick={()=>closeModal()}>Cancel</div>
                          </section>

                          <section style={{display:'flex',alignItems:'center',justifyContent:'end'}}>
                              <div className='saveEditCard' onClick={()=>dispatch(deleteCustomParameters({accessToken:accessToken,key:parameterKey}))}>Proceed</div>
                          </section>
                      </div>
                  </>
        setParamUiState(data)
    }

    else if(selfScoreDef.deleteCustomParameterStatus=== FetchDataStatus.FETCHING){
        setParamUiState(<Spinner/>)
    }

    else if(selfScoreDef.deleteCustomParameterStatus=== FetchDataStatus.SUCCESS){
      const data = <>
                        <section style={{margin:'10px 0px',padding:'0px 0px',textAlign:'center'}}>
                          <i>Parameter successfully deleted.</i>
                        </section>

                        <div style={{display:"flex",justifyContent:"flex-end",columnGap:"15px"}}>
                            <section style={{display:'flex',alignItems:'center',justifyContent:'end'}}>
                                <div className='saveEditCard' onClick={()=>{dispatch(resetParameterByKey());closeModal()}}>Close</div>
                            </section>
                        </div>
                    </>
      setParamUiState(data)
    }

    else if(selfScoreDef.deleteCustomParameterStatus=== FetchDataStatus.FAILURE){
      const data = <>
                        <p style={{textAlign:'center',fontWeight:"700",fontSize:"20px"}}>Something went wrong.</p>
                        <section style={{margin:'10px 0px',padding:'0px 0px',textAlign:'center'}}>
                          <i>{selfScoreDef.deleteCustomParameterErrorMessage}</i>
                        </section>

                        <div style={{display:"flex",justifyContent:"flex-end",columnGap:"15px"}}>
                            <section style={{display:'flex',alignItems:'center',justifyContent:'end'}}>
                                <div className='saveEditCard' onClick={()=>closeModal()}>Close</div>
                            </section>
                        </div>
                    </>
      setParamUiState(data)
    }

},[selfScoreDef.deleteCustomParameterStatus,parameterKey])


  return (
    <div style={{height:'200px',paddingTop:"20px",display:'flex',flexDirection:"column",justifyContent:"space-around"}}>
        <section style={{display:'flex',alignItems:'center',justifyContent:"space-between"}}>
            <div style={{display:'flex',flexDirection:'column',rowGap:"10px"}}>
                <span style={{color:'rgba(31, 34, 39, 1)',fontSize:"24px",fontWeight:'700'}}>
                    Delete Custom Parameter
                </span>
            </div>
          
        </section>

        <>
          {paramUiState}
        </>
        
        
    </div>
  )
}

export default DeleteParameterModals