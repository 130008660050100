import React,{useState,useEffect} from "react";
import { useSelector,useDispatch } from "react-redux";
import { PostDataStatus, FetchDataStatus } from "../../library/Variables";
import { selectMutilTenantSlice,getAllTenants } from "../../redux/Slices/Multinenant/MultiTenancyFile";
import Utils from "../../library/Utils";
import notificationBell from '../../assests/svg-icons/notificationBell.svg'
import NigeriaIcon from '../../assests/svg-icons/nigeriaIcon.svg'
import tenantArrow from '../../assests/svg-icons/tenantArrow.svg'
import { CountryCodesAndFlag } from "../../library/CountryCodes";
import './NavBar.css';

function NavBar(props) {
  const user = (props.user).user;
  const configuration = props.configuration;
  const [selectedOption, setSelectedOption] = useState('');
  const multiTenantsState = useSelector(selectMutilTenantSlice)
  const [permittedTenants,setPermittedTenants] = useState([])
  const [tenantState,setTenantState] = useState('')
  const dispatch = useDispatch()
  const [isOpen,setIsOpen] = useState(false)
  const [selectedCode,setSelectedCode] = useState('')
  const accessToken = localStorage.getItem('insightsAccessToken');
  const loggedInDate = localStorage.getItem("insightsLoggedInTime");

  const flagSelector = (id)=>{
    const tenantSelected = CountryCodesAndFlag.filter(element=>element.code===id)
    return tenantSelected[0].flag
  }

  
  useEffect(()=>{
      if(multiTenantsState.fetchTenantStatus===FetchDataStatus.INITIAL){
        dispatch(getAllTenants())
      }
      if(multiTenantsState.fetchTenantStatus===FetchDataStatus.SUCCESS){
        if(!Utils.isFieldEmpty(multiTenantsState?.fetchTenantData?.linkedTenants[0])){
          let tenantPermitted = multiTenantsState?.fetchTenantData?.linkedTenants.filter(element=>user.allowedTenants.includes(element.identifier))
          let tenantSelected = tenantPermitted.filter(element=>element.isCurrentTenant===true)
          setPermittedTenants(tenantPermitted)
          if(!Utils.isFieldEmpty(tenantSelected[0])){
            setTenantState(' '+Utils.capitalizeFirstLetter(tenantSelected[0].identifier) )
            setSelectedCode(tenantSelected[0].countryCode)
          }
        }
      }
  },[multiTenantsState])

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(()=>{
    if(!Utils.isFieldEmpty(selectedOption)){

      const hold = permittedTenants.filter(element=>element.identifier===selectedOption)
      changeTenant(hold[0].redirectUrl)
    }
  },[selectedOption])


  const changeTenant = (redirectUrl)=>{
    const cleanRedirectUrl = redirectUrl.endsWith('/') ? redirectUrl.slice(0, -1) : redirectUrl;

    window.location.href = `${cleanRedirectUrl}?tkValue=${accessToken}&tkDate=${loggedInDate}`
  }
  //<span>{tenantState}</span>
  return (
    <div className="navbar" >
      <section style={{
                      flexDirection:"column",
                      display:"flex",
                      justifyContent:"space-between",
                      alignItems:"flex-start",
                      }}>
          {user && <h2 className="detail-header">Welcome, {user.firstName}</h2>}
          <p>These are the latest statement updates for {configuration.displayName}</p>
      </section>
      
      <section>
      {(multiTenantsState.fetchTenantStatus===FetchDataStatus.SUCCESS 
          && 
        !Utils.isFieldEmpty(selectedCode)) 
        && 
      <div style={{
      display:"flex",
      justifyContent:"end",
      padding:'5%'
    }}>
      <section
        style={{
          width:"auto",
  
        }}>
        <section className="mainTenantHold">
          <div >
            <div 
              className="tenantSelectorUl" 
              onClick={() => setIsOpen(!isOpen)}
            >
              <section style={{width:'85%'}}>
                {selectedOption ?
                  <section style={{display:"flex",columnGap:'10px'}}>
                    {!Utils.isFieldEmpty(selectedCode) && 
                    <span className="alignImages">
                      <img src={flagSelector(selectedCode)}/>
                    </span>
                    }
                    <span>{selectedOption}</span>
                  </section>
                
                  : 
                  <section style={{display:"flex",
                                    columnGap:'10px',
                                    alignItems:"center",
                                    width:'100%',
                                    }}>
                    {!Utils.isFieldEmpty(selectedCode) && 
                    <span className="alignImages">
                      <img src={flagSelector(selectedCode)}/>
                    </span>
                    }
                    <section className="tenantTextHolder">
                    {tenantState}
                    </section>
                  </section>
                }
              </section>

              <section className="alignImages">
                <img src={tenantArrow}/>
              </section>
            </div>
            {isOpen && (
              <ul className="tenantList">
                {permittedTenants.map((option) => (
                  <li 
                    key={option.identifier} 
                   
                    
                    onClick={() => {setSelectedOption(option.identifier) ;selectedCode(option.countryCode)}}
                  >
                    <img 
                      src={flagSelector(option.countryCode)} 
                      alt={option.label} 
                      style={{width:'30px'}}
                    />
                    <section className="indTenantName">
                        {Utils.capitalizeFirstLetter(option.identifier)}
                    </section>
                  </li>
                ))}
              </ul>
            )}
          </div>

            
          <span className="bellIcon" >
            <img src={notificationBell}/>
          </span>
        </section>
        
      </section>
    </div>}
      </section>
    </div>
  )
}

export default NavBar;

//{Utils.capitalizeFirstLetter(option.identifier)}