import React, { useMemo, useState } from 'react'
import { Table, SelectColumnFilter, DateRangeColumnFilter } from '../../../components/Table/Table';import Utils from '../../../library/Utils';
import { useNavigate, Link } from "react-router-dom";
import { FetchDataStatus, CustomCreditScoreViews, PostDataStatus } from "../../../library/Variables";
import Form from '../../../components/Form/Form';
import { 
  useSelector,
  useDispatch
} from 'react-redux';
import {selectCustomDecisionCreditScoreState, getAllCustomDecisionEngineScores} from "../../../redux/Slices/CustomDecisionCustomCreditScore"
import { getParticularCustomDecisionCreditScore } from '../../../redux/Slices/CustomDecisionCustomCreditScore';
import './HistoryPage.css'
import { resetSelfService } from '../../../redux/Slices/SelfServiceDecision';

import Spinner from "../../../svg/Spinner/Spinner";

function HistoryPage({setPageActive, update, configuration,preparedTableData, updateInitialView, scoreData, handleViewScore, accessToken, user}) {
  let content = <Spinner />;
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const filteredResult = useSelector(selectCustomDecisionCreditScoreState)
  const [isActiveSearch, setIsActiveSearch] = useState(false);

    function handleParentCallback(activeFields) {
      setIsActiveSearch(true);
      dispatch(getAllCustomDecisionEngineScores(activeFields));
    }

    const params = { 
      accessToken: accessToken,
      startDate: '',
      endDate: '',
      name: '',
      type: ''
    };

    function handleFormReset() {
      params.startDate = '';
      params.endDate = '';
      params.name = '';
      params.type = "";
  
      setIsActiveSearch(false);
      dispatch(getAllCustomDecisionEngineScores(params));
    }
   

    const navigateToSelfService = ()=>{
      dispatch(resetSelfService());
      navigate('/DecisionEngineSelfService')

    }


    const scoreHistory = useMemo(() => [
        {
            Header: 'Customer ID',
            accessor: 'customerId',
        },
        { 
          Header: 'Scorecard Name',
          accessor: 'scorecardName',
        },        
        { 
            Header: 'Created Date',
            accessor: 'createdDate',
            Filter: DateRangeColumnFilter,
            filter: 'dateRange'
        },
        {
            id: 'button-column',
            disableSortBy: true,
            Cell: data => {
              return (
                <div className="table-row-buttons-container">
                  <button state={{ key: data.row.original.key }} onClick={() => {dispatch(getParticularCustomDecisionCreditScore({accessToken:accessToken,key:data.row.original.key}));
                   updateInitialView();
                   handleFormReset()
                  
                  }} className="button-square table-row-buttons edit button-view">View</button>
                </div>
              );
            }
        }
    ])


    const searchFormFields = [
      {
        type: "text",
        id: "st-name-search",
        placeholder: "Customer ID",
        param: "name",
        className: "form-default-container name"
      },
      {
        type: "DATE_RANGE",
        params: { first: 'startDate', second: 'endDate' },
        header: { value: 'Insert date range', label: 'Created Date Range' },
        className: "form-date-range-container",
      },
      {
        type: "SELECT",
        hiddenOptionLabel: "Scorecard",
        options: filteredResult?.customDecisionCustomScoreCards,
        id: "st-type-search",
        param: "type",
        className: "form-select-container type"
      }
    ]

    //preparedTableData.sort((first, second) => new Date(second.date) - new Date(first.date));
    if(scoreData?.length !== 0 ) {
      if( filteredResult.customDecisionStatementStatus === FetchDataStatus.FETCHING) {
        content = <Spinner />
      }else {
        content = <div className='score-history-table'>
            <Table tableClass="accounts-type-table consolidated-overview-table all-accounts-table" pagination={true} pageSize={[10]} data={preparedTableData} columns={scoreHistory}
                tableExtra={false} tableFilter={false} />   
          </div>  
      }
    }else {
      content = <div className="cs-ns-current-container">
      <div className="cs-current-header">
          <h2 className="detail-header">There are currently no Credit Scores to display.</h2>
      </div>
      <p>Please click the Generate Score or <a href={"mailto:support@periculum.io"} 
          className='link-color'>contact us</a> for more information.</p>
    </div>
    }


  return (
    <>
        <div className='subpage-header'>
            <div>
                <h3>Score History</h3>
                <p>See all scores generated so far</p>
            </div>
          
        </div>

        {
          (user.user.permissions.checkScorecard === true || user.user.permissions?.viewCurrentScore === true) && 
          <> 
            <div className='dash-body decision-engine'>
              <div className="st-search-container" 
                id={Utils.isFieldEmpty(preparedTableData) && isActiveSearch === false ? "no-statements-seach" : null}>
                <Form header={"Search"} accessToken={accessToken} parentCallback={(activeFields) => handleParentCallback(activeFields)} 
                  searchFormFields={searchFormFields} />
              </div>
            </div>
            <div className='selfBoxHold'>
            
              {
              (user.user.permissions.generateScorecard === true) && <>
                <section className='selfGenerate' onClick={update} >Generate Score</section>
              </>}
           
            </div>

            { content }
          </>
        }




    </>
  )
}

export default HistoryPage