import React,{useEffect, useState} from 'react'
import { resetParameter,resetDeleteCustomParam,resetGetRulesByKey} from '../../../../../redux/Slices/SelfServiceDecision'
import UploadParameterModal from '../../../Modals/UploadParameterModal'
import { PostDataStatus } from '../../../../../library/Variables'
import { useDispatch } from 'react-redux'
import dropDownIcon from '../../../../../assests/icons/arrowDownWhite.png'
import DeleteIconRed from '../../../../../assests/svg-icons/DeleteIconRed.svg'
//import dropDownIcon from '../../../../../assets/svg-icons/arrowDownParam.svg'

import DeleteParameterModals from '../../../Modals/DeleteParameterModals'
import Modal from "react-modal";
import Utils from '../../../../../library/Utils'
import './ParameterList.css'

function ParameterList({setSection,selfScoreDef,setcreateParamIsOpen,setMainParameterKey,setShowCardCreated,setcreateRuleIsOpen,accessToken,name,customDecisionCard,setShowScoreCard,setParamMetaData}) {
  const [headers, setHeader] = useState(['Parameter Name','Type of Parameter','Last Updated','Action'])
  const [deleteParamModalIsOpen, setDeleteParamModalIsOpen] = useState(false);
  const [uploadParameterModalIsOpen, setUploadParameterModalIsOpen] = useState(false);
  const [parameterKey,setParameterKey] = useState('')


  const dispatch = useDispatch()
  const [showParameter,setShowParameter] = useState(false)
 

    function afterOpenModal() { }

    const deleteParamRecord = (key)=>{
        setParameterKey(key)
        dispatch(resetDeleteCustomParam())
        setDeleteParamModalIsOpen(true)
        
    }

    function closeDeletParameterModal() {
        setDeleteParamModalIsOpen(false)

    //dispatch(resetDownload());
    }

    function closeUploadParameterModal() {
        setUploadParameterModalIsOpen(false)

    //dispatch(resetDownload());
    }

  const customStyles = {
    content: {
      transition: '0.125s ease-in-out',
      transform: 'scale(1)',
      height: '33%',
      width: '80%',
      top: '33%',
      left: '33%',
      padding: '20px',
      boxSizing:'border-box',
    },
    overlay: {
      zIndex: 1000,
      position: 'absolute'
    }
  };

  const editParam = (val)=>{
    if(setMainParameterKey){
        setMainParameterKey(val.key)
    }
    if(setShowCardCreated){
        setShowCardCreated(false)
    }
    if(setShowScoreCard){
        setShowScoreCard(false)
    }
    setParamMetaData(val)
    
    dispatch(resetGetRulesByKey())
  }


  return (
    <div>
       
                <section>
                    <div style={{display:"flex",justifyContent:'space-between',alignItems:"center",marginBottom:'30px'}}>
                        <div>
                            <span style={{fontSize:"24px",fontWeight:"700",color:"black"}}>Parameter</span>
                        </div>
                       <div style={{position:"relative",width:"fit-content",display:"flex",columnGap:"10px"}}>
                            <div className='uploadParameter' onClick={()=>setUploadParameterModalIsOpen(true)}>
                                <span style={{fontSize:"18px",fontWeight:"700",color:"#2A38A4"}}>Upload Parameter</span> 
                            </div>

                            <section style={{position:"relative"}}>
                                <div className='createParameter' onClick={()=>setShowParameter(!showParameter)}>
                                    <span style={{fontSize:"18px",fontWeight:"700",color:"white"}}>Create Parameter</span>
                                    <img className='img-flex' src={dropDownIcon}/>
                                    
                                </div>

                                {
                                    showParameter && 
                                    <section className='showParam'>
                                        <span onClick={()=>{setcreateParamIsOpen(true);
                                                            setShowParameter(false);
                                                            dispatch(resetGetRulesByKey());
                                                            dispatch(resetParameter())}}>
                                            Custom Parameter
                                        </span>

                                        {customDecisionCard && <span onClick={()=>{setcreateRuleIsOpen(true);
                                                                                    dispatch(resetGetRulesByKey());
                                                                                    dispatch(resetParameter());
                                                                                    setShowParameter(false)}}>
                                            Defined Parameter
                                        </span>}
                                    </section>
                                }

                            </section>
                            
                        </div>
                        
                
            </div>

            <div style={{marginTop:'20px'}}>{Utils.isFieldEmpty(selfScoreDef.selfServiceParameterByKeyData)?
                <>
                    <section className='EmptyParams'>
                        <i className='title' >There is currently no Parameter to display.</i>
                        <span className='proceed' >Please, click the button above to proceed.</span>
                    </section>
                </>
                :
                <>
                    <section style={{display:"flex",justifyContent:"space-between"}}>
                        {headers.map((head,index)=><section style={{fontWeight:'700',
                                                                    fontSize:'16px',
                                                                    width:"25%",
                                                                    paddingLeft:"10px",
                                                                    boxSizing:"border-box"}} 
                                                                    key={index}>
                                                                    {head}
                                                                    </section>)
                        }
                    </section>
                    <section style={{display:"flex",
                                     justifyContent:"space-between",
                                     height:'auto',
                                     maxHeight:'300px',
                                     overflow:'auto',
                                     flexDirection:"column",
                                     rowGap:'25px',
                                     marginTop:"10px"}}>
                        {selfScoreDef.selfServiceParameterByKeyData.map((item,index)=>
                            <div key={index} style={{display:"flex",
                                                     padding:"10px 0px",
                                                     width:"100%",
                                                     justifyContent:"space-between",
                                                     borderRadius:"7px",
                                                     backgroundColor:"white",
                                                     alignItems:'center'}}>
                                <section style={{fontWeight:'500',
                                                 fontSize:'16px',
                                                 width:"25%",
                                                 color:'#1F2227',
                                                 paddingLeft:"20px",
                                                 boxSizing:"border-box"}}>{item.name}

                                </section>
                                <section style={{fontWeight:"400",
                                                 fontSize:'16px',
                                                 width:"25%",
                                                 paddingLeft:"20px",
                                                 boxSizing:"border-box"}}>
                                                 {item.type}
                                </section>
                                <section style={{fontWeight:"400",
                                                 fontSize:'16px',
                                                 width:"25%",
                                                 paddingLeft:"20px",
                                                 boxSizing:"border-box"}}>
                                                 {!Utils.isFieldEmpty(item.createdDate)?item.createdDate:'--' }</section>
                                <div style={{display:"flex",justifyContent:'left',width:"25%",columnGap:'45px',paddingLeft:"30px",boxSizing:"border-box"}}>
                                    <section className='editButton' onClick={()=>editParam(selfScoreDef.selfServiceParameterByKeyData[index])}>Edit</section>
                                    <span className='imageHolder' onClick={()=>deleteParamRecord(item.key)}><img src={DeleteIconRed}/></span>
                                </div>
                            </div>
                            )}
                    </section>
                </>
                
                }  
            </div>
            </section>

        <Modal isOpen={deleteParamModalIsOpen} 
               onAfterOpen={afterOpenModal} 
               style={customStyles}
               contentLabel="Delete Parameter Modal" >
               <DeleteParameterModals closeModal={closeDeletParameterModal}
                                    accessToken={accessToken}
                                    parameterKey={parameterKey}
                                    name={name}
                                    />
        </Modal>

        <Modal isOpen={uploadParameterModalIsOpen} 
               onAfterOpen={afterOpenModal} 
               style={customStyles}
               contentLabel="Upload Parameter Modal" >
            <UploadParameterModal accessToken={accessToken}  
                                  close={closeUploadParameterModal}y
                                  setUploadParameterModalIsOpen={setUploadParameterModalIsOpen}
                                  />
        </Modal>
             
    </div>
  )
}

export default ParameterList

//onClick={()=>navigate('/DecisionEngineSelfService/ParameterCreation')}