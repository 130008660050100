import React,{useState,useEffect} from 'react'
import NewSection from './NewSection'
import Utils from '../../../../library/Utils'
import DefinedNewSection from './DefinedNewSection'
import Spinner from '../../../../svg/Spinner/Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { getScoreCardRules,selectSelfServiceState,getSelfServiceScoreCardDefinitions,getSelfServiceCustomParamsByKey } from '../../../../redux/Slices/SelfServiceDecision'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




function NewRule({setRulesLength,mainParameterKey,scoreCardKey,mainScoreCardKey,rulesLength,setMainParameterKey,setParamName,setParameterType,accessToken,customDecisionCard,triggerSaveRules,setTriggerSubmit,paramName,parameterType,scoreCard,definitionRules,setDefinitionRules}) {
    const [sectionLength,setSectionLength] = useState([{key:'1',content:{}}])
    const [ruleSaveable,setRuleSaveAble] = useState(false)
    const dispatch = useDispatch()
    const [param,setParam] = useState()
    const selfServiceState = useSelector(selectSelfServiceState)
    const [ruleObj,setRuleObj] = useState({name:'',displayType:'',customParameterKey:''})
    const [triggerSection,setTriggerSection] = useState(false)
    const [triggerNewRule,setTriggerNewRule] = useState(false)
    
    const removeRules = (index)=>{
        let mainArr = [...rulesLength]
        mainArr.splice(index,1)
        setRulesLength(mainArr)
    }

    useEffect(()=>{
        if(customDecisionCard){
            if(!Utils.isFieldEmpty(mainScoreCardKey)){
                dispatch(getScoreCardRules({accessToken,key:mainScoreCardKey}))
            }
            else if(!Utils.isFieldEmpty(scoreCardKey)){
                dispatch(getScoreCardRules({accessToken,key:scoreCardKey}))
            }
            
        }
    },[])

    useEffect(()=>{
        if(!Utils.isFieldEmpty(param) ){
            setRuleSaveAble(true)
            const ruleData = selfServiceState.scoreCardRuleData[param]
            setRuleObj({name:paramName,displayType:ruleData.displayType,
            customParameterKey:parseInt(mainParameterKey)})
        }
        else{
            setRuleSaveAble(false)
        }

    },[param])

    const saveRules = (index)=>{
        let newRanges = [];
        sectionLength.map((section,index)=>newRanges.push(section.content)) 
        const ruleFinalObj = {
            ruleName:paramName,
            customParameterKey:parseInt(mainParameterKey),
            ruleRanges: [...newRanges]
        }
        const newDefinitionRules = [...definitionRules]
        newDefinitionRules[index] = ruleFinalObj
        setDefinitionRules(newDefinitionRules)
        setRuleSaveAble(false)
    }
  
    useEffect(()=>{
        if(triggerSaveRules){
            setTriggerSection(true)    
        }
    },[triggerSaveRules])

    useEffect(()=>{
        if(triggerNewRule){
            saveRules(0)
            setTriggerSubmit(true)
        }
    },[triggerNewRule])
  
    useEffect(()=>{
        if (!Utils.isFieldEmpty(scoreCard)) {
            dispatch(getScoreCardRules({accessToken,key:scoreCard}))
        }
    },[scoreCard])
    

    

    useEffect(()=>{
  
        dispatch(getSelfServiceScoreCardDefinitions({accessToken}))
    },[])

    const getRulesList = ()=>{
        if(Utils.isFieldEmpty(scoreCard)){
            toast.info('Select Score Card first.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
        }
    }
  return (
        <section style={{marginBottom:'10px'}}>
            <div style={{backgroundColor:'white',padding:"15px",height:"100%",borderRadius:'10px',
                    overflowY:'auto',display:"flex",flexDirection:"column",
                    justifyContent:"space-between"}}>
            
            <>
                {customDecisionCard?
                    <>
                        {sectionLength.map((section,index)=>
                            <DefinedNewSection section={section} 
                                    sectionLength={sectionLength} 
                                    setSectionLength={setSectionLength} 
                                    parameterType={parameterType}
                                    index={index} 
                                    ruleObj={ruleObj}
                                    triggerSection ={triggerSection}
                                    setTriggerNewRule={setTriggerNewRule}
                                    setMainParameterKey={setMainParameterKey}
                                    setParamName={setParamName}
                                    setParameterType={setParameterType}
                                    customDecisionCard={customDecisionCard}
                                    />)
                        }

                    </>:
                    <>
                        {sectionLength.map((section,index)=>
                            <NewSection section={section} 
                                sectionLength={sectionLength} 
                                setSectionLength={setSectionLength} 
                                parameterType={parameterType}
                                index={index} 
                                ruleObj={ruleObj}
                                triggerSection ={triggerSection}
                                setTriggerNewRule={setTriggerNewRule}
                                customDecisionCard={customDecisionCard}
                                />)
                        }
                    </>
                }
            </>

         
          
            
            </div>

        </section>
        
     
  )
}

export default NewRule